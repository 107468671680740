import { Box, Grid } from "@mui/material"
import { NoDataFound } from "../../../empty/Index"
// import { SnakeLoader } from "../../../../../core/loader/SnakeLoader"

export const Tbody = ({salesBranchList, generateForm}) => {
    
    return (
        <Box height="100%" width="100%" bgcolor="#FFFFFF" borderRadius="12px" className="overflowY" display="flex" flexDirection="column" gap="12px" p={2}>
            {/* {generateForm.infiniteLoad && 
                <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                    <SnakeLoader size="1rem" bg="#11783C" distance="0.3rem" />
                </Box>
            } */}
            {(!generateForm.infiniteLoad && salesBranchList.length === 0) && <NoDataFound/>}
            {(!generateForm.infiniteLoad && salesBranchList.length > 0) && salesBranchList.map((v,k) => (
                <Grid key={k} container direction="row" sx={{p:2, border:'1px solid #EBEBEB', borderRadius:'10px'}}>
                    <Grid item xs={2.5}>{v.name}</Grid>
                    <Grid item xs={2.5}>{v.first_name} {v.last_name}</Grid>
                    <Grid item xs={1.5} textAlign="center">{v.date}</Grid>
                    <Grid item xs={2} textAlign="center">₱ {parseFloat(v.total_sales).toFixed(2)}</Grid>
                    <Grid item xs={2} textAlign="center">₱ {parseFloat(v.actual_sales).toFixed(2)}</Grid>
                    <Grid item xs={1.5} textAlign="center">₱ {parseFloat(v.variance).toFixed(2)}</Grid>
                </Grid>
            ))}
        </Box>
    )
}