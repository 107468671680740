import { TransitionGroup } from 'react-transition-group';
import { Box, Collapse, Divider, IconButton } from "@mui/material"
import { EditProfile, NewAddRoundIcon, NewRemoveRoundIcon } from "../../../../../core/global/Icons"
import { CstmTextField } from "../../../../../core/global/forms/CstmTextField";
import { AntSwitch, IOSSwitch } from "../../../../../core/global/forms/CstmToggle";

export const Form = (props) => {
    const { loc, cid, categoryForm, setCategoryForm } = props

    const removeRowHandler = (e, index) => {
        let data = [...categoryForm]
        data.splice(index, 1)
        data = data.map((d, k) => ({...d, series_num:`CTGRY-000000000${cid + k + 1}` }))
        setCategoryForm(data)
    }

    const inputChangeHandler = (e, type, index, subIndex) => {
        const {name, value} = e.target
        if (type === 'main') {
            const data = [...categoryForm];
            data[index][name] = name === 'active' ? e.target.checked ? 1 : 0 : value 
            data[index]['error'] = {...data[index]['error'], stat: data[index]['category_name'] === '' ? true : false } 
            setCategoryForm(data)
        }

        if (type === 'sub') {
            let count = 0
            if (loc !== 'create' && value !== '') {
                count = categoryForm[0].sub.filter((v,k)=> {return v.sub_name.toLowerCase() === value.toLowerCase()} ).length
            }
            setCategoryForm((prev) => prev.map((v,k) => 
                k === index ?
                    {...v, sub:v.sub.map((t,i) => 
                        i === subIndex ? 
                            {...t, [name]: name === 'active' ? e.target.checked ? 1 : 0 : value, sub_error:{...t.sub_error, name:count === 1 ? true:false, stat: value==='' ? true:false } }
                        :{...t}  ) } 
                : {...v} 
            ))
        }
    }

    const addRowSubHandler = (index) => {
        setCategoryForm((prev) => prev.map((v, k) => k === index ? {...v, sub:[{id:0, sub_name:'', sub_error:{stat:true, msg:'Fields Required!'}}, ...v.sub ] } : {...v}))
    }

    const removeRowSubHandler = (e, index, subIndex, subdata) => {
        let data = [...subdata]
        data.splice(subIndex, 1)
        setCategoryForm((prev) => prev.map((v,k) => k === index ? {...v, sub:data } : {...v} ))
    }

    const editHandler = (e, val) => {
        e.stopPropagation()
        setCategoryForm((prev) => prev.map(v => { return {...v, sub:v.sub.map(s => s.id === val.id ? {...s, edit:true} : {...s}) } }  ))
    }
    
    return (
        <Box height="auto" className="overflowY">
            <TransitionGroup style={{display:'flex', flexDirection:'column', gap:'16px'}}>
                {categoryForm?.map((v,k) => (
                    <Collapse key={k}>
                        <Box minHeight="100px" bgcolor="#ffffff" borderRadius="10px" display="flex" flexDirection="column" justifyContent="center" gap={3} px={3} py={5}>
                            <Box display="flex" alignItems="center" justifyContent="space-between" gap={3}>
                                <Box>{v.series_num}</Box>
                                {categoryForm.length > 1 && (
                                    <IconButton onClick={(e) => removeRowHandler(e, k)} variant="contained" sx={{bgcolor:'#D8D5D5', ':hover':{bgcolor:'#E4F0E9'} }}>
                                        <NewRemoveRoundIcon/>
                                    </IconButton>
                                )}
                            </Box>  
                            <Box display="flex" flexDirection="column">
                                <CstmTextField disabled={loc !== 'create' ? v.is_default === 1? true : false : false} onInput={(e) => inputChangeHandler(e, 'main', k, '' )} fullWidth variant="outlined" label="Category Name" name="category_name" value={v.category_name} error={v.error.stat} />
                                <Box minHeight="20px" display="flex" alignItems="center" justifyContent="flex-end" fontSize={9} color="red">
                                    {v.error.name && 'Category Already Exist!'}
                                </Box>
                                {loc !== 'create' && (
                                    <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                                        <IOSSwitch name="active" checked={v.active === 1} onChange={(e) => inputChangeHandler(e, 'main', k, '')}/>
                                        <Box fontSize={12}>Active</Box>
                                    </Box>
                                )}
                            </Box>  
                            <Divider/>
                            <Box display="flex" flexDirection="column" gap={3}>
                                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" gap={1} minHeight="30px" pr="13px">
                                    <Box fontWeight={700}>Sub-Category</Box>
                                    <Box display={'flex'} alignItems="center" gap={1}>
                                        <IconButton onClick={()=>addRowSubHandler(k)} variant="contained" sx={{bgcolor:'primary.main', ':hover':{bgcolor:'#1B984F'} }} >
                                            <NewAddRoundIcon height="14" width="14"/>
                                        </IconButton>
                                    </Box>
                                </Box>
                                <Box width="100%" display={'flex'} flexDirection="column" alignItems="center">
                                    {v.sub?.map((s,i) => (
                                        <Box key={i} width="100%" display='flex' alignItems="center" gap={2}>
                                            <Box width="100%" display="flex" flexDirection="column">
                                                <CstmTextField disabled={loc !== 'create' ? s.is_default === 1 || (!s.edit && s.id !== 0)? true : false : false} onInput={(e) => inputChangeHandler(e, 'sub', k, i )} fullWidth variant="outlined" label="Sub-Category Name" name="sub_name" value={s.sub_name} error={s.sub_error.stat || s.sub_error.name} 
                                                    InputProps={{
                                                        endAdornment: (
                                                            (v.sub.length > 1 && (s.is_default !== 1 || s.is_default === undefined ) ) && (
                                                                loc === 'create' || s.id === 0 ? 
                                                                    <IconButton onClick={(e) => removeRowSubHandler(e, k, i, v.sub)} variant="contained" sx={{
                                                                        bgcolor:'#D8D5D5', ':hover':{bgcolor:'#E4F0E9'} }}>
                                                                        <NewRemoveRoundIcon height="14" width="14"/>
                                                                    </IconButton>
                                                                :
                                                                <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                                                                    {!s.edit && (
                                                                        <Box fontWeight={500} className="c-pointer" onClick={(e) => editHandler(e, s)}>
                                                                            <EditProfile height="18" fill={'none'} stroke={'#292d32'}/>
                                                                        </Box>
                                                                    )}
                                                                    <Box display="flex" alignItems="center" gap={.5}>
                                                                        <AntSwitch name="active" checked={s.active === 1} onChange={(e) => inputChangeHandler(e, 'sub', k, i)}/>
                                                                        <Box fontSize={12}>Active</Box>
                                                                    </Box>
                                                                </Box>
                                                            )
                                                        ),
                                                        }}    
                                                />
                                                <Box minHeight="20px" display="flex" alignItems="center" justifyContent="flex-end" fontSize={9} color="red">
                                                    {s.sub_error.name && 'Sub Category Already Exist!'}
                                                </Box>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    </Collapse>
                ))}
            </TransitionGroup>    
        </Box>
    )
}