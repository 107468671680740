import { Box, Skeleton } from "@mui/material"

export const Loader = () => { 
    return (
        <Box height="350px" width="100%" bgcolor="#FFFFFF" borderRadius="4px" p={2} display="flex" flexDirection="column" gap={1}>
            <Skeleton variant="rectangular" sx={{width:'100%', height:'20px', borderRadius:'6px 6px 0px 0px ', bgcolor:'#F0F6F3'}} />
            <Box width="100%" display="flex" flexDirection="column" gap={1} py={2}>
                {['','',''].map((v,k) => (
                    <Box key={k} width="100%" display="flex" gap={2} >
                        <Skeleton variant="text" sx={{width:'80px', height:'20px', borderRadius:'6px 6px 0px 0px ', bgcolor:'#F0F6F3'}} />
                        <Skeleton variant="text" sx={{width:'120px', height:'20px', borderRadius:'6px 6px 0px 0px ', bgcolor:'#F0F6F3'}} />
                    </Box>
                ))}
            </Box>
            <Box width="100%" display="flex" flexDirection="column" gap={1}>
                <Skeleton variant="text" sx={{width:'80px', height:'20px', borderRadius:'6px 6px 0px 0px ', bgcolor:'#F0F6F3'}} />
                <Skeleton variant="rectangular" sx={{width:'100%', height:'50px', borderRadius:'6px 6px 0px 0px ', bgcolor:'#F0F6F3'}} />
            </Box>
            <Box minHeight="80px" width="100%" py={2}>
                <Skeleton variant="rectangular" sx={{width:'100%', height:'50px', borderRadius:'6px 6px 0px 0px ', bgcolor:'#F0F6F3'}} />
            </Box>
        </Box>
    )
}