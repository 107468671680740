import { TransitionGroup } from 'react-transition-group';
import { Autocomplete, Box, Collapse, IconButton } from "@mui/material"
import { NewRemoveRoundIcon } from "../../../../../core/global/Icons"
import { CstmTextField } from "../../../../../core/global/forms/CstmTextField";
import { IOSSwitch } from "../../../../../core/global/forms/CstmToggle";

export const Form = (props) => {
    const { loc, pid,  productForm, setProductForm, submitFormLoader, setSubmitFormLoader, uomData } = props

    const removeRowHandler = (e, index) => {
        let data = [...productForm]
        data.splice(index, 1)
        data = data.map((d, k) => ({...d, series_num:`PR-00000000${pid + k + 1}`}))
        setProductForm(data)
    }

    const inputChangeHandler = async(e, index, val, key) => {
        let name
        let value
        if (key === 'uom_id' ) {
            name = key;
            value = (val === null ? 0 : (val.id > 0 ? val.id:val));
        }else{
            name = e.target.name
            value = e.target.value
        }
        const data = [...productForm];
        data[index][name] = name === 'active' ? e.target.checked ? 1 : 0 : value  
        data[index]['error'] = {...data[index]['error'], 
            stat: data[index]['product_name'] === '' || data[index]['uom_id'] === 0 ? true : false 
        } 
       
        setProductForm(data)
        const show = data.filter(f => { return f.error.stat } ).length
        setSubmitFormLoader({...submitFormLoader, submit:show===0?false:true})
    }

    return (
        <Box height="auto" className="overflowY">
            <TransitionGroup style={{display:'flex', flexDirection:'column', gap:'16px'}}>
                {productForm?.map((v,k) => (
                    <Collapse key={k}>
                        <Box minHeight="100px" bgcolor="#ffffff" borderRadius="10px" display="flex" flexDirection="column" gap={3} px={3} py={2}>
                            <Box display="flex" alignItems="center" justifyContent="space-between" gap={3}>
                                <Box>{v.series_num}</Box>
                                {productForm.length > 1 && (
                                    <IconButton onClick={(e) => removeRowHandler(e, k)} variant="contained" sx={{bgcolor:'#D8D5D5', ':hover':{bgcolor:'#E4F0E9'} }}>
                                        <NewRemoveRoundIcon/>
                                    </IconButton>
                                )}
                            </Box>
                            <Box display="flex" flexDirection="column">
                                <Box width="100%" display="flex" flexDirection="column" gap={1}>
                                    <Box width="100%" display="flex" gap={3}>
                                        <Box width="100%" display="flex" flexDirection="column">
                                            <CstmTextField onInput={(e) => inputChangeHandler(e, k)} fullWidth variant="outlined" label="Product Name" name="product_name" value={v.product_name} error={(v.product_name === '' && v.error.stat) || v.error.name}/>
                                            <Box minHeight="20px" display="flex" alignItems="center" justifyContent="flex-end" fontSize={9} color="red">
                                                {v.error.name && 'Product Name Already Exist!'}
                                            </Box>
                                        </Box>
                                        <Autocomplete   
                                            fullWidth
                                            options={uomData.data}
                                            getOptionLabel={(option) => option.name}
                                            onChange={(e, value) => inputChangeHandler(e, k, value, 'uom_id')}
                                            value={ v.uom_id === '' ? null : uomData.data.find((f) => f.id === v.uom_id) || null} 
                                            renderInput={(params) => (
                                                <CstmTextField {...params} label="Uom" placeholder="UOM" error={v.uom_id === 0 && v.error.stat}/>
                                            )}
                                        />
                                    </Box>
                                    <Box width="100%" display="flex" flexDirection="column">
                                        <CstmTextField multiline rows={3} onInput={(e) => inputChangeHandler(e, k)} fullWidth variant="outlined" label="Product Description" name="description" value={v.description} />
                                    </Box>
                                    {loc !== 'create' && (
                                        <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                                            <IOSSwitch name="active" checked={v.active === 1} onChange={(e) => inputChangeHandler(e, k, '', '')}/>
                                            <Box fontSize={12}>Active</Box>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Collapse>
                )).reverse()}
            </TransitionGroup>    
        </Box>
    )
}