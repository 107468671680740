import { useHistory } from "react-router-dom";
import { Box, Button } from "@mui/material"
import { ArrowLeftIcons } from "../../../../../core/global/Icons"
import { BranchProductCreate, BranchProductUpdate } from "../../../global/API";
import { S3 } from '../../../../../core/transmit/S3'

export const Bottom = (props) => {
    let nav = useHistory()
    const { loc, user_id, __SESSION, loader, setLoader, submitFormLoader, setSubmitFormLoader, branchProductForm, setBranchProductForm } = props

    const backHandler = () => {
        nav.push('/setup/branch-product/list')
    }

    const submitHandler = async() => {
        setSubmitFormLoader((prev) => ({...prev, loader:true}))
        if (loc === 'create') {
            const data = [...branchProductForm]
            // const data = branchProductForm.map(({ error, series_num, ...rest}) => { return {...rest, company_id:__SESSION.data.uaccess[0].company_id, user_id:user_id, 
            //     product:rest.product.map(({ prod_error, sub_cat, ...prod_rest}) => prod_rest)
            // }});
            for (let i = 0; i < data.length; i++) {
                let prod_data = data[i].product

                for (let l = 0; l < prod_data.length; l++) {
                    let res_s3 = prod_data[l].file_name === '' ? {location:'https://marketing-system-repo.s3.amazonaws.com/pos/dev/1723626668785.png'} : await S3(prod_data[l].file)
                    let body = {
                        company_id: __SESSION.data.uaccess[0].company_id, 
                        user_id:user_id,
                        branch_id:data[i].branch_id,
                        show:2,
                        category_id:prod_data[l].category_id,
                        sub_category_id:prod_data[l].sub_category_id,
                        product_id:prod_data[l].product_id,
                        price:prod_data[l].price,
                        discount:prod_data[l].discount,
                        quantity:prod_data[l].quantity,
                        file_name:prod_data[l].file_name,
                        file_size:prod_data[l].file_size,
                        file_type:prod_data[l].file_type,
                        link:res_s3.location
                    }
                    let res = await BranchProductCreate(body)
                    
                    if (res.msg === 'name_exist') {
                        const prodIndex = prod_data.filter((v, k) => { return l <= k } );
                        const prodmap = prodIndex.map(v => v.category_id === prod_data[l].category_id && v.sub_category_id === prod_data[l].sub_category_id && v.product_id === prod_data[l].product_id ? {...v, prod_error:{...v.prod_error, product_id:res.branch_id === data[i].branch_id} } : {...v} )
                        data[i]['product'] = prodmap
                        const finalData = data.filter((v, k) => { return i <= k } );
                        setBranchProductForm(finalData)
                        setLoader({...loader, snackbar:true, msg:`Product Name Already exist!`})
                        setSubmitFormLoader((prev) => ({...prev, loader:false}))
                        return
                    }else{  
                        const subdata = prod_data.filter((v, k) => { return l < k } );
                        if (subdata.length === 0) {
                            setBranchProductForm(
                                (prev) => (prev.filter(v=>{return v.id!==data[i].id} ))
                            )
                        }
                    }
                }
            }
        }else{
            let data = branchProductForm[0]
            let prod_data = data.product[0]
            let file_link = prod_data.file_size === '' ? prod_data.file_link : await S3(prod_data.file)
            let body = {
                id:data.id,
                company_id: __SESSION.data.uaccess[0].company_id, 
                user_id:user_id,
                branch_id:data.branch_id,
                show:2,
                category_id:prod_data.category_id,
                sub_category_id:prod_data.sub_category_id,
                product_id:prod_data.product_id,
                price:prod_data.price,
                discount:prod_data.discount,
                quantity:prod_data.quantity,
                file_name:prod_data.file_name,
                file_size:prod_data.file_size,
                file_type:prod_data.file_type,
                link: file_link.location ? file_link.location : prod_data.file_link,
                active:data.active,
            }
            let res = await BranchProductUpdate(body)
            if (res === 'name_exist') {
                const data = [...branchProductForm]
                data[0]['product'][0]['prod_error'] = {...data[0]['product'][0]['prod_error'], product_id:true}
                setBranchProductForm(data)
                setSubmitFormLoader((prev) => ({...prev, loader:false}))
                setLoader({...loader, snackbar:true, msg:`Product Name Already exist!`})
                return
            }
           
        }
        setLoader({...loader, snackbar:true, msg:`Successfully ${loc === 'create' ? 'Added!' : 'Updated!'}`})
        setSubmitFormLoader((prev) => ({...prev, loader:false}))
        nav.push('/setup/branch-product/list')
    }

    return (
        <Box minHeight="80px" display="flex" alignItems="center" justifyContent="flex-end" gap={2}>
            <Box onClick={backHandler} component={Button} disableFocusRipple={true} minHeight="50px" minWidth="120px" borderRadius={3} display="flex" alignItems="center" justifyContent="center" bgcolor="#fff" color="#00000061" sx={{border:"1px solid #EBEBEB"}} startIcon={<ArrowLeftIcons stroke="#00000061"/>} px={3}>
                <Box fontSize={14} fontWeight={400} lineHeight= "19px">Back</Box>
            </Box>
            <Box disabled={submitFormLoader.submit} onClick={submitHandler} component={Button} disableFocusRipple={true} minHeight="50px" minWidth="120px" borderRadius={3} display="flex" alignItems="center" justifyContent="center" bgcolor={submitFormLoader.submit?"#fff":"primary.main"} color={submitFormLoader.submit?"#00000061":"#fff"} sx={{border:"1px solid #EBEBEB", ':hover':{bgcolor:'primary.main', color:'#FFFFFF'}}} px={3}>
                <Box fontSize={14} fontWeight={400} lineHeight="19px">{loc === 'create' ? 'Save' : 'Update'}</Box>
            </Box>
        </Box>
    )
}