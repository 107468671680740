import { useEffect } from "react";
import { Box, Button } from '@mui/material';
import { Sync as SyncIcon } from '@mui/icons-material';
import { SalesProductReport } from "../../../global/API";
import { DataSetArray } from "./DataSetArray";
import Excel from '../../../../../assets/images/icons/excel.png';
import ReactExport from 'react-data-export';
import moment from "moment/moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export const Generate = (props) => {
    const { __SESSION, snackBarLoader, form, comProductIds, generateForm, setGenerateForm, salesProductData, setSalesProductData, salesProductList } = props

    const handleGenerate = async() => {
        await __generateData(0)
    }

    const __generateData = async(offset) => {
        if (form.to === '' || form.from === '' || form.to < form.from) {
            snackBarLoader.set({...snackBarLoader.data, snackbar:true, msg: `Invalid Date!`})
            return
        }else{
            setGenerateForm((prev) => ({...prev, infiniteLoad:true}))
        }
        let qsp = {
            branch_id:form.branch_id,
            company_id:__SESSION.data.uaccess[0].company_id,
            product_id:form.product_id > 0 ? form.branch_id === 0 ? JSON.stringify(comProductIds) : JSON.stringify([form.product_id]) : form.product_id,
            from:form.from,
            to:form.to,
            limit:generateForm.limit,
            offset:offset   
        }
        let res = await SalesProductReport(qsp)
        if (res) {
            if (res.length === 0) {
                FinalData([...salesProductData, ...res])
                return
            }else{
                ///// LOADER VALIDATION
                if (res.length < generateForm.limit) {
                    FinalData([...salesProductData, ...res])
                    return
                }else{
                    setGenerateForm((prev) => ({...prev, offset:res.length + salesProductData.length}))
                }
                ///// STATE LIST DATA
                setSalesProductData([...salesProductData, ...res])
            }   
        }
    }

    const FinalData = (salesProductData) => {
        salesProductList.set(salesProductData)
        setGenerateForm((prev) => ({...prev, infiniteLoad:false, offset:0}))
        setSalesProductData([])
    }

    useEffect(() => {
        if (generateForm.offset !== 0) {
            __generateData(generateForm.offset)
        }
        //eslint-disable-next-line
    }, [salesProductData])

    return (
        <Box minHeight="55px" display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
            {salesProductList.data.length > 0 && (
                <ExcelFile filename={`Sales_Product_Reports_${moment(new Date()).format('MM/DD/YYYY')}`}
                    element={
                        <Box display="flex" alignItems="center" className="c-pointer" bgcolor="#FFFFFF" borderRadius={2} padding="12px 16px">
                            <img src={Excel} alt="excel-img" />
                            <Box ml="10px" color="#1D6F42">Export in excel</Box>
                        </Box>
                    }
                >
                    <ExcelSheet dataSet={DataSetArray(salesProductList.data)} name="Reports" />
                </ExcelFile>
            )}
            <Box onClick={handleGenerate} component={Button} disableFocusRipple={true} minHeight="50px" minWidth="130px" borderRadius={3} display="flex" alignItems="center" justifyContent="center" bgcolor="primary.main" color="#fff" sx={{border:"2px solid #EBEBEB", ':hover':{bgcolor:'primary.main', color:'#FFFFFF'}}} startIcon={<SyncIcon/>}>
                <Box fontSize={16} fontWeight={400} lineHeight="19px">Generate</Box>
            </Box>
        </Box>
    )
}