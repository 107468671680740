import { Autocomplete, Box, Grid } from "@mui/material"
import { CstmTextField } from "../../../../../core/global/forms/CstmTextField"

export const Thead = ({inputChangeHandler, form, branchData, branchProductData, snackBarLoader}) => {
    
    return (
        <Box minHeight="170px" display="flex" flexDirection="column" justifyContent="center" gap={1.5}>
            <Box width="100%" display="flex" alignItems="center" gap={3} bgcolor="#FFFFFF" borderRadius="12px" p={2}>
                <Autocomplete   
                    fullWidth
                    options={branchData.data}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, value) => inputChangeHandler(e, value, 'branch_id')}
                    value={ form.branch_id === '' ? null : branchData.data.find((f) => f.id === form.branch_id) || null} 
                    renderInput={(params) => (
                        <CstmTextField {...params} label="Branch" placeholder="Branch" />
                    )}
                />
                <Autocomplete   
                    fullWidth
                    options={branchProductData}
                    getOptionLabel={(option) => (option.sub_cat_name +' '+ option.product_name)}
                    key={option => option.id}
                    onChange={(e, value) => inputChangeHandler(e, value, 'product_id')}
                    value={ form.product_id === '' ? null : branchProductData.find((f) => f.id === form.product_id) || null} 
                    renderOption={(props, option) => {
                        return (
                        <li {...props} key={option.id}>
                            {option.sub_cat_name +' '+ option.product_name}
                        </li>
                        );
                    }}
                    renderInput={(params) => (
                        <CstmTextField {...params} label="Product" placeholder="Product" />
                    )}
                />
                 <CstmTextField fullWidth type="date" variant="outlined" label="Date From" name="from" InputLabelProps={{shrink: true}} 
                InputProps={{ inputProps: { max:"9999-12-31"}}} onInput={(e) => inputChangeHandler(e)} value={form.from} error={snackBarLoader.data && form.from === ''}/>
                <CstmTextField fullWidth type="date" variant="outlined" label="Date To" name="to" InputLabelProps={{shrink: true}} InputProps={{inputProps: {min: form.from, max:"9999-12-31"}}} onInput={(e) => inputChangeHandler(e)} value={form.to} error={((snackBarLoader.data && form.to === '') || (form.to < form.from))}/>
            </Box>
            <Grid container direction="row" sx={{py:2, px:4, bgcolor:'#EBEBEB', borderRadius:'10px'}}>
                <Grid item xs={2.5}>Branch</Grid>
                <Grid item xs={3}>Product</Grid>
                <Grid item xs={2.5} textAlign="center">Date&Time</Grid>
                <Grid item xs={2} textAlign="center">Qty Sold</Grid>
                <Grid item xs={2} textAlign="center">Total Sale</Grid>
            </Grid>
        </Box>
    )
}