import { lazy, useContext } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { VM } from '../VM';

import { Index as ToolCategories } from '../../__business/components/toolcategories/Index';
import { RenderAPI } from '../../__business/components/global/RenderAPI';
import { Switching } from '../../__business/components/swtiching/Index'
import { Settings } from '../../__business/components/settings/Index';

import { Setup } from '../../__business/components/setup/Index';

// import { Products } from '../../__business/components/setup/product/Index'
// import { Orders } from '../../__business/components/order/Index'
import { Reports } from '../../__business/components/reports/Index'
import { ToolContext } from '../context/ToolContext';

let view = VM();
const Navigation = lazy(() => import(`../../__business/components/layouts/navigations${view}/Index`))
const Home = lazy(() => import (`../../__business/components/home${view}/Index`))

export const WebRoute = () => {
    const location = useLocation().pathname;
    const { tool_state } = useContext(ToolContext);
    const { __SESSION } = tool_state
    // console.log(__SESSION.data.uaccess[0].role_name)

    return (
        <Switch>
            {/* HOME */}
            <Route exact path="/" render={props=>(Render(Home, props, location))}/>
            <Route exact path="/reports/:features" render={props=>(Render(Reports, props, location))}/>
            <Route exact path="/switching" render={props=><Switching/>} />
            <Route exact path="/more" render={props=>(Render(Settings, props, location))} />
            <Route exact path="/tool-categories/:params" render={props=>(Render(ToolCategories, props, location))} />
            {__SESSION.data.uaccess[0].role_name !== 'Regular User' && (
                <Route>
                    <Route exact path="/setup/:features/:params" render={props=>(Render(Setup, props, location))} />
                </Route>
            )}
            {/* <Route exact path="/products/:params" render={props=>(Render(Products, props, location))} /> */}
            {/* <Route exact path="/orders" render={props=>(Render(Orders, props, location))} /> */}
           
            {/* 404 NOT FOUND */}   
            <Route>
                <Container>
                    <Box display="flex">
                        Error 404
                    </Box>
                </Container>
            </Route>
        </Switch>
    )
}

const Render = (Component, props, location) => (
    <>
        {view === '' ? (
            <Box width="100%" height="100%">
                <Box width="100%" height="100%" display="flex">
                    <Box height="100%">
                        {(location !== '/profiling') && (<Navigation side={1} />)}
                    </Box>
                    <Box display="flex" flexDirection="column" width="100%" height="100%">
                        <Navigation side={0} />
                        <Box height="100%" className="overflowY noScrollcss">
                            <Component {...props} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        ) : (
            <Box width="100%" height="100%">
                <Box width="100%" height="100%" display="flex" flexDirection="column">
                    {(location !== '/profiling' && location !== '/more') && (
                        <Box minHeight={location === '/' ? '92px' : '40px'}>
                            <Navigation side={0} />
                        </Box>
                    )}
                    <Box height="100%" width="100%" className="overflowY noScrollcss"><Component {...props} /></Box>
                    {(location !== '/profile' && location !== '/profiling') &&
                        <Box minHeight="54px">
                            <Navigation side={2} />
                        </Box>
                    } 
                </Box>
            </Box>
        )}
        <RenderAPI/>
    </>
)