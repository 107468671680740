import { useHistory } from "react-router-dom";
import { Box, Button } from "@mui/material"
import { ArrowLeftIcons } from "../../../../../core/global/Icons"
import { BranchUserCreate, BranchUserUpdate } from "../../../global/API";

export const Bottom = (props) => {
    let nav = useHistory()
    const { loc, user_id, __SESSION, loader, setLoader, submitFormLoader, setSubmitFormLoader, branchUserForm, setBranchUserForm } = props

    const backHandler = () => {
        nav.push('/setup/branch-user/list')
    }

    const submitHandler = async() => {
        setSubmitFormLoader((prev) => ({...prev, loader:true}))
        if (loc === 'create') {
            const data = [...branchUserForm]
            
            for (let i = 0; i < data.length; i++) {
                let body = {
                    company_id: __SESSION.data.uaccess[0].company_id, 
                    user_id:user_id,
                    branch_id:data[i].branch_id,
                    branch_user_id:data[i].user_id,
                }
                let res = await BranchUserCreate(body)
                if (res.msg === 'name_exist') {
                    setBranchUserForm(
                        (prev) => (prev.map(v=>v.id===data[i].id ? {...v, error:{...v.error, name:res.msg === 'name_exist' }} : {...v} ))
                    )
                    setLoader({...loader, snackbar:true, msg:`Branch and User Name Already exist!`})
                    setSubmitFormLoader((prev) => ({...prev, loader:false}))
                    return
                }else{
                    setBranchUserForm(
                        (prev) => (prev.filter(v=>{return v.id!==data[i].id} ))
                    )
                }
            }
        }
        else{
            let data = branchUserForm[0]
            let body = {
                id:data.id,
                company_id: __SESSION.data.uaccess[0].company_id, 
                user_id:user_id,
                branch_id:data.branch_id,
                branch_user_id:data.user_id,
                active:data.active,
            }
            let res = await BranchUserUpdate(body)
            if (res === 'name_exist') {
                const data = [...branchUserForm]
                data[0]['error'] = {...data[0]['error'], name:true}
                setBranchUserForm(data)
                setSubmitFormLoader((prev) => ({...prev, loader:false}))
                setLoader({...loader, snackbar:true, msg:`Branch and User Name Already exist!`})
                return
            }
           
        }
        setLoader({...loader, snackbar:true, msg:`Successfully ${loc === 'create' ? 'Added!' : 'Updated!'}`})
        setSubmitFormLoader((prev) => ({...prev, loader:false}))
        nav.push('/setup/branch-user/list')
    }


    return (
        <Box minHeight="80px" display="flex" alignItems="center" justifyContent="flex-end" gap={2}>
            <Box onClick={backHandler} component={Button} disableFocusRipple={true} minHeight="50px" minWidth="120px" borderRadius={3} display="flex" alignItems="center" justifyContent="center" bgcolor="#fff" color="#00000061" sx={{border:"1px solid #EBEBEB"}} startIcon={<ArrowLeftIcons stroke="#00000061"/>} px={3}>
                <Box fontSize={14} fontWeight={400} lineHeight= "19px">Back</Box>
            </Box>
            <Box disabled={submitFormLoader.submit} onClick={submitHandler} component={Button} disableFocusRipple={true} minHeight="50px" minWidth="120px" borderRadius={3} display="flex" alignItems="center" justifyContent="center" bgcolor={submitFormLoader.submit?"#fff":"primary.main"} color={submitFormLoader.submit?"#00000061":"#fff"} sx={{border:"1px solid #EBEBEB", ':hover':{bgcolor:'primary.main', color:'#FFFFFF'}}} px={3}>
                <Box fontSize={14} fontWeight={400} lineHeight="19px">{loc === 'create' ? 'Save' : 'Update'}</Box>
            </Box>
        </Box>
    )
}