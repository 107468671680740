import { Box, Grid } from "@mui/material"

export const Tfooter = ({salesBranchList}) => {

    let totalsales = salesBranchList.reduce((prev, current) => {
        return prev + +current.total_sales;
    }, 0);
    let totalactualsales = salesBranchList.reduce((prev, current) => {
        return prev + +current.actual_sales;
    }, 0);
    let total_variance = salesBranchList.reduce((prev, current) => {
        return prev + +current.variance;
    }, 0);
    
    return (
        <Box minHeight="70px">
            <Grid container direction="row" sx={{ py:1.5, px:2}}>
                <Grid item xs={2.5}></Grid>
                <Grid item xs={2.5}></Grid>
                <Grid item xs={1.5} textAlign="center"></Grid>
                <Grid item xs={2} textAlign="center" fontWeight={700}>₱ {parseFloat(totalsales).toFixed(2)}</Grid>
                <Grid item xs={2} textAlign="center" fontWeight={700}>₱ {parseFloat(totalactualsales).toFixed(2)}</Grid>
                <Grid item xs={1.5} textAlign="center" fontWeight={700}>₱ {parseFloat(total_variance).toFixed(2)}</Grid>
            </Grid>
            <Grid container direction="row" sx={{ px:2}}>
                <Grid item xs={2.5}></Grid>
                <Grid item xs={2.5}></Grid>
                <Grid item xs={1.5} textAlign="center"></Grid>
                <Grid item xs={2} textAlign="center" fontWeight={700}>Total Sales</Grid>
                <Grid item xs={2} textAlign="center" fontWeight={700}>Total Actual Sales</Grid>
                <Grid item xs={1.5} textAlign="center" fontWeight={700}>Total Variance</Grid>
            </Grid>
        </Box>
    )
}