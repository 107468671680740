import { useState } from "react"

export const SetUpState = () => {
    const [searchInput, setSearchInput] = useState('');
    const [infiniteState, setInfiniteState] = useState({limit:20, offset:0, infiniteLoad:false, dataType:'string'});
    const [listLoader, setListLoader] = useState({isloading:true})
    const [snackBarLoader, setSnackBarLoader] = useState({snackbar:false, msg:'', type:''})
   
    const [branchData, setBranchData] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [branchSearchList, setBranchSearchList] = useState([]);
    
    const [categoryData, setCategoryData] = useState([]);
    const [categoryList, setCategoryList] = useState([])
    const [categorySearchList, setCategorySearchList] = useState([])
    
    const [productData, setProductData] = useState([]);
    const [productList, setProductList] = useState([])
    const [productSearchList, setProductSearchList] = useState([])
    
    const [branchProductData, setBranchProductData] = useState([]);
    const [branchProductList, setBranchProductList] = useState([])
    const [branchProductSearchList, setBranchProductSearchList] = useState([])

    const [branchUserData, setBranchUserData] = useState([]);
    const [branchUserList, setBranchUserList] = useState([])
    const [branchUserSearchList, setBranchUserSearchList] = useState([])

    const [uomData, setUomData] = useState([]);
    const [toolUser, setToolUser] = useState([]);
    
    return {
        searchInput: {data: searchInput, set: setSearchInput},
        infiniteState: {data: infiniteState, set: setInfiniteState},
        listLoader: {data: listLoader, set: setListLoader},
        snackBarLoader: {data: snackBarLoader, set: setSnackBarLoader},
        branchData: {data: branchData, set: setBranchData},
        branchList: {data: branchList, set: setBranchList},
        branchSearchList: {data: branchSearchList, set: setBranchSearchList},
        categoryData: {data: categoryData, set: setCategoryData},
        categoryList: {data: categoryList, set: setCategoryList},
        categorySearchList: {data: categorySearchList, set: setCategorySearchList},
        productData: {data: productData, set: setProductData},
        productList: {data: productList, set: setProductList},
        productSearchList: {data: productSearchList, set: setProductSearchList},
        branchProductData: {data: branchProductData, set: setBranchProductData},
        branchProductList: {data: branchProductList, set: setBranchProductList},
        branchProductSearchList: {data: branchProductSearchList, set: setBranchProductSearchList},
        branchUserData: {data: branchUserData, set: setBranchUserData},
        branchUserList: {data: branchUserList, set: setBranchUserList},
        branchUserSearchList: {data: branchUserSearchList, set: setBranchUserSearchList},
        uomData: {data: uomData, set: setUomData},
        toolUser: {data: toolUser, set: setToolUser},
    }
}