import { Backdrop, Box, CircularProgress, Stack } from "@mui/material"

export const BackDropLoader = ({submitFormLoader}) => {

    return (
        <Backdrop open={submitFormLoader.loader} sx={{zIndex: '1300'}}>
            <Box height="150px" width="100%" display="flex" alignItems="center" justifyContent="center">
                <Box height="100%" width="270px" borderRadius="12px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={3}>
                    <Stack sx={{ color: 'grey.300' }} spacing={2} direction="row">
                        <CircularProgress color="inherit"  />
                    </Stack>
                    <Box color="#fff">Please wait while saving...</Box>
                </Box>
            </Box>
        </Backdrop>
    )
}