import { Box, Grid } from "@mui/material"

export const Tfooter = ({salesProductList}) => {
    
    let total_qty = salesProductList.reduce((prev, current) => {
        return prev + +current.quantity;
    }, 0);
    let total_sales = salesProductList.reduce((prev, current) => {
        return prev + +current.amount;
    }, 0);

    return (
        <Box minHeight="70px">
            <Grid container direction="row" sx={{ py:1.5, px:2}}>
                <Grid item xs={2.5}></Grid>
                <Grid item xs={3} textAlign="center"></Grid>
                <Grid item xs={2.5} textAlign="center" fontWeight={700}></Grid>
                <Grid item xs={2} textAlign="center" fontWeight={700}>{total_qty}</Grid>
                <Grid item xs={2} textAlign="center" fontWeight={700}>₱ {parseFloat(total_sales).toFixed(2)}</Grid>
            </Grid>
            <Grid container direction="row" sx={{ px:2}}>
                <Grid item xs={2.5}></Grid>
                <Grid item xs={3} textAlign="center"></Grid>
                <Grid item xs={2.5} textAlign="center"></Grid>
                <Grid item xs={2} textAlign="center" fontWeight={700}>Total Quantity Sold</Grid>
                <Grid item xs={2} textAlign="center" fontWeight={700}>Total Sales</Grid>
            </Grid>
        </Box>
    )
}