import { TransitionGroup } from 'react-transition-group';
import { Box, Collapse, IconButton } from "@mui/material"
import { NewRemoveRoundIcon } from "../../../../../core/global/Icons"
import { CstmTextField } from "../../../../../core/global/forms/CstmTextField";
import { IOSSwitch } from "../../../../../core/global/forms/CstmToggle";

export const Form = (props) => {
    const { loc, bid, branchForm, setBranchForm, submitFormLoader, setSubmitFormLoader } = props

    const removeRowHandler = (e, index) => {
        let data = [...branchForm]
        data.splice(index, 1)
        data = data.map((d, k) => ({...d, id: bid + k + 1}))
        setBranchForm(data)
    }

    const inputChangeHandler = async(e, index) => {
        const {name, value} = e.target
        const data = [...branchForm];
        data[index][name] = name === 'active' ? e.target.checked ? 1 : 0 : value 
        data[index]['error'] = {...data[index]['error'], stat: data[index]['code'] === '' || data[index]['name'] === '' ? true : false } 
        setBranchForm(data)
        const show = data.filter(f => { return f.error.stat } ).length
        setSubmitFormLoader({...submitFormLoader, submit:show===0?false:true})
    }

    return (
        <Box height="auto" className="overflowY">
            <TransitionGroup style={{display:'flex', flexDirection:'column', gap:'16px'}}>
                {branchForm?.map((v,k) => (
                    <Collapse key={k}>
                        <Box minHeight={loc === 'create' ? "120px":"140px"} bgcolor="#ffffff" borderRadius="10px" display="flex" alignItems="center" gap={3} px={3} pt="20px">
                            <Box width="100%" display="flex" flexDirection="column">
                                <Box width="100%" display="flex" gap={3}>
                                    <Box width="100%" display="flex" flexDirection="column">
                                        <CstmTextField disabled={loc === 'create' ? false : true} onInput={(e) => inputChangeHandler(e, k)} fullWidth variant="outlined" label="Branch Code" name="code" value={v.code} error={v.error.stat || v.error.code}/>
                                        <Box minHeight="20px" display="flex" alignItems="center" justifyContent="flex-end" fontSize={9} color="red">
                                            {v.error.code && 'Branch Code Already Exist!'}
                                        </Box>
                                    </Box>
                                    <Box width="100%" display="flex" flexDirection="column">
                                        <CstmTextField onInput={(e) => inputChangeHandler(e, k)} fullWidth variant="outlined" label="Branch Name" name="name" value={v.name} error={v.error.stat || v.error.name} />
                                        <Box minHeight="20px" display="flex" alignItems="center" justifyContent="flex-end" fontSize={9} color="red">
                                            {v.error.name && 'Branch Name Already Exist!'}
                                        </Box>
                                    </Box>
                                </Box>
                                {loc !== 'create' && (
                                    <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                                        <IOSSwitch name="active" checked={v.active === 1} onChange={(e) => inputChangeHandler(e, k)}/>
                                        <Box fontSize={12}>Active</Box>
                                    </Box>
                                )}
                            </Box>
                            {branchForm.length > 1 && (
                                <IconButton onClick={(e) => removeRowHandler(e, k)} variant="contained" sx={{marginBottom:'20px', bgcolor:'#D8D5D5', ':hover':{bgcolor:'#E4F0E9'} }}>
                                    <NewRemoveRoundIcon/>
                                </IconButton>
                            )}
                        </Box>  
                    </Collapse>
                )).reverse()}
            </TransitionGroup>    
        </Box>
    )
}