import { useContext, useState } from "react"
import { useHistory } from "react-router-dom"
import { Box, Button, Container } from "@mui/material"
import { Branch } from "./branch/Index"
import { Category } from "./category/Index"
import { BranchProduct } from "./branchProduct/Index"
import { BranchUser } from "./branchUser/Index"
import { Product } from "./product/Index"
import { ToolContext } from "../../../core/context/ToolContext"

export const Setup = ({match}) => {
    let loc = match.params.features

    const { setup_state } = useContext(ToolContext)
    const { branchProductList, branchProductSearchList, branchUserList, branchUserSearchList, searchInput } = setup_state
    const nav = useHistory()
    const [tabs] = useState([
        {name:'Branch', ref:'branch'},
        {name:'Category', ref:'category'},
        {name:'Product', ref:'product'},
        {name:'Branch Product', ref:'branch-product'},
        {name:'Branch User', ref:'branch-user'},
    ])

    const redirectHandler = async(ref) => {
        if (ref !== loc) {
            await searchInput.set('')
            await branchProductList.set([])
            await branchProductSearchList.set([])
            await branchUserList.set([])
            await branchUserSearchList.set([])
            nav.push(`/setup/${ref}/list`)        
        }
    }

    return (
        <Box component={Container} width="100%" height="100%"  display="flex" flexDirection="column" py={3}>
            <Box minHeight="100px" display="flex" flexDirection="column">
                <Box minHeight="48px" display="grid" gridTemplateColumns="repeat(auto-fill,minmax(150px,1fr))" gap="8px">
                    {tabs?.map((v, k) => (
                        <Box onClick={()=>redirectHandler(v.ref)} key={k} component={Button} disableFocusRipple={true} minWidth="150px" borderRadius={3} display="flex" flexDirection="column" alignItems="center" gap="12px" bgcolor={loc === v.ref ?"primary.main":"#FFFFFF"} color={loc === v.ref ?"#fff":"#283745"} sx={{border:"2px solid #EBEBEB", ':hover':loc === v.ref && {bgcolor:'primary.main', color:'#FFFFFF'}}} >
                            <Box fontSize={12} fontWeight={400} lineHeight="19px" width="100%" sx={{wordBreak: 'break-word'}}>{v.name}</Box>
                        </Box>
                    ))}
                </Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }} py={1}></Box>
            </Box>
            <Box height="100%" sx={{overflowY:'hidden'}}>
                <Components loc={loc} match={match}/>
            </Box>
            <Box minHeight="30px"></Box>
        </Box>
    )
}

const Components = ({loc, match}) => {
    if (loc === 'branch') { return <Branch match={match}/>
    }else if (loc === 'category') { return <Category match={match}/>
    }else if (loc === 'product') { return <Product match={match}/>
    }else if (loc === 'branch-product') { return <BranchProduct match={match}/>
    }else if (loc === 'branch-user') { return <BranchUser match={match}/>
    }else{return 'NOT FOUND'}
}