import moment from "moment"

export const DataSetArray = (salesProductList) => {
    
    let total_qty = salesProductList.reduce((prev, current) => {
        return prev + +current.quantity;
    }, 0);
   
    let total_sales = salesProductList.reduce((prev, current) => {
        return prev + +current.amount;
    }, 0);
   
    let footer =   [
        {type:'footer', branch_name: '', sub_cat_name:'', product_name:'', created_at:'', quantity:'', amount:''},
        {type:'footer', branch_name: '', sub_cat_name:'', product_name:'', created_at:'', quantity:'', amount:''},
        {type:'footer', branch_name: '', sub_cat_name:'', product_name:'', created_at:'', quantity:total_qty, amount:`₱ ${parseFloat(total_sales).toFixed(2)}`},
        {type:'footer', branch_name: '', sub_cat_name:'', product_name:'', created_at:'', quantity:'Total Quantity Sold', amount:'Total Sales'},
    ]
    
    const DataSet = [
        {
            columns: [
                {title:"Branch",style:{alignment:{horizontal:"center"}, font:{sz:"18",bold:true}},width:{wpx:150}},
                {title:"Product",style:{alignment:{horizontal:"center"}, font:{sz:"18",bold:true}},width:{wpx:200}},
                {title:"Date/Time",style:{alignment:{horizontal:"center"}, font:{sz:"18",bold:true}},width:{wpx:150}},
                {title:"Quantity Sold",style:{alignment:{horizontal:"center"}, font:{sz:"18",bold:true}},width:{wpx:170}},
                {title:"Total Sales",style:{alignment:{horizontal:"center"}, font:{sz:"18",bold:true}},width:{wpx:150}},
            ],
            data:[...salesProductList, ...footer].map((v) => [
                {value: v.branch_name, style:{alignment:{horizontal:"center"}} },
                {value: `${v.sub_cat_name} ${v.product_name}`, style:{alignment:{horizontal:"center"}} },
                {value: v.type === 'footer' ? v.created_at:moment(v.created_at).format('YYYY-MM-DD h:mm a'), style:{alignment:{horizontal:"center"}} },
                {value: v.quantity, width:{wpx:170}, style: {alignment:{horizontal:"center"}, font: v.type === 'footer' && {sz:"18",bold:true}} },
                {value: v.type === 'footer' ? v.amount:`₱ ${parseFloat(v.amount).toFixed(2)}`, 
                    width:{wpx:150}, 
                    style: {alignment:{horizontal:v.amount === 'Total Sales' ? "center" : "right"}, font: v.type === 'footer' && {sz:"18",bold:true}} 
                },
            ])
        }
    ]
    return DataSet
}