import { useState } from "react"

export const ReportStates = () => {
    const [eodSales, setEodSales] = useState({total_sales:0, actual_sales:0, eod:0, variance:0})
    const [salesProductList, setSalesProductList] = useState([])

    return {
        eodSales: {data: eodSales, set: setEodSales},
        salesProductList: {data: salesProductList, set: setSalesProductList}
    }
}