import { TransitionGroup } from 'react-transition-group';
import { Autocomplete, Box, Collapse, Divider, IconButton } from "@mui/material"
import { NewAddRoundIcon, NewRemoveRoundIcon } from "../../../../../core/global/Icons"
import { CstmTextField } from "../../../../../core/global/forms/CstmTextField";
import { IOSSwitch } from "../../../../../core/global/forms/CstmToggle";
import { CategoryRead } from "../../../global/API";
import { DropzoneWrapper } from "./DropeZoneWrapper";

export const Form = (props) => {
    const {loc, bpid, branchProductForm, setBranchProductForm, loader, setLoader, submitFormLoader, setSubmitFormLoader, branchData, categoryData, productData } = props
    const removeRowHandler = (e, index) => {
        let data = [...branchProductForm]
        data.splice(index, 1)
        data = data.map((d, k) => ({...d, series_num:`BP-000000000${bpid + k + 1}` }))
        setBranchProductForm(data)
    }

    const inputChangeHandler = async(e, type, index, subIndex, val, key) => {
        let name
        let value
        if (key === 'category_id' || key === 'sub_category_id' || key === 'product_id' || key === 'branch_id') {
            name = key;
            value = (val === null ? 0 : (val.id > 0 ? val.id:val));
        }else{
            name = e.target.name
            value = e.target.value
        }
        const data = [...branchProductForm];

        if (type === 'main') {
            data[index][name] = name === 'active' ? e.target.checked ? 1 : 0 : value  
            data[index]['error'] = {...data[index]['error'], stat: data[index]['branch_id'] === 0 ? true : false } 
        }

        if (type === 'sub') {
            if (key === 'category_id') {
                if (value > 0) {
                    let res = await CategoryRead(value, 'sub-category', 'active')
                    if (res) {
                        data[index]['product'][subIndex]['sub_cat'] = res
                    }
                }else{
                    data[index]['product'][subIndex]['sub_cat'] = []
                }
            }
            if (name === 'quantity' || name === 'price') {
            // if (name === 'price') {
                const regex = /^[0-9]*\.?[0-9]*$/;
                if (regex.test(value)) {
                    data[index]['product'][subIndex][name] = value
                }
            }else{
                data[index]['product'][subIndex][name] = value
            }
            data[index]['product'][subIndex]['prod_error'] = {...data[index]['product'][subIndex]['prod_error'], 
                stat: data[index]['product'][subIndex]['product_id'] === 0 || data[index]['product'][subIndex]['category_id'] === 0 || data[index]['product'][subIndex]['sub_category_id'] === 0 || data[index]['product'][subIndex]['quantity'] === '' || data[index]['product'][subIndex]['quantity'] === '.' || data[index]['product'][subIndex]['quantity'] < 0.1 || data[index]['product'][subIndex]['price'] === '' || data[index]['product'][subIndex]['price'] === '.' || data[index]['product'][subIndex]['price'] < 0.1 || data[index]['product'][subIndex]['discount'] === '' ? true : false 
            } 
        }
        if (loc !== 'create') {
            data[0]['error'] = {...data[0]['error'], stat:data[0]['branch_id'] === 0}
            data[0]['product'][0]['prod_error'] = {...data[0]['product'][0]['prod_error'], 
                stat:data[0]['product'][0]['category_id'] === 0 || data[0]['product'][0]['sub_category_id'] === 0 || data[0]['product'][0]['product_id'] === 0 || data[0]['product'][0]['quantity'] === '' || data[0]['product'][0]['quantity'] === '.' || data[0]['product'][0]['quantity'] < 0.1 || data[0]['product'][0]['price'] === '' || data[0]['product'][0]['price'] === '.' || data[0]['product'][0]['price'] < 0.1 || data[0]['product'][0]['discount'] === ''
            }
        }
        setBranchProductForm(data)
        const allTrue = data.some(item => item.error.stat || item.product.some(subItem => subItem.prod_error.stat)) ? 1 : 0;
        setSubmitFormLoader({...submitFormLoader, submit:allTrue!==0})
    }

    const addRowSubHandler = (index) => {
        setBranchProductForm((prev) => prev.map((v, k) => k === index ? {...v, product:[...v.product, {
            category_id:0,
            sub_category_id:0,
            sub_cat:[],
            product_id:0,
            price:0, 
            quantity:0, 
            discount:0,
            prod_error:{stat:true, product_id:false, msg:'Fields Required!'},
            file:[],
            file_name:'',
            file_size:'',
            file_type:'',
            file_link:''
        } ] } : {...v}))
    }

    const removeRowSubHandler = (e, index, subIndex, subdata) => {
        let data = [...subdata]
        data.splice(subIndex, 1)
        setBranchProductForm((prev) => prev.map((v,k) => k === index ? {...v, product:data} : {...v} ))
    }

    return (
        <Box height="auto" className="overflowY">
            <TransitionGroup style={{display:'flex', flexDirection:'column', gap:'16px'}}>
                {branchProductForm?.map((v,k) => (
                    <Collapse key={k}>
                        <Box minHeight="100px" bgcolor="#ffffff" borderRadius="10px" display="flex" flexDirection="column" justifyContent="center" gap={3} p={3}>
                            <Box display="flex" alignItems="center" justifyContent="space-between" gap={3}>
                                <Box>{v.series_num}</Box>
                                {branchProductForm.length > 1 && (
                                    <IconButton onClick={(e) => removeRowHandler(e, k)} variant="contained" sx={{bgcolor:'#D8D5D5', ':hover':{bgcolor:'#E4F0E9'} }}>
                                        <NewRemoveRoundIcon/>
                                    </IconButton>
                                )}
                            </Box>  
                            <Box display="flex" flexDirection="column">
                                <Autocomplete   
                                    fullWidth
                                    options={branchData.data}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e, value) => inputChangeHandler(e, 'main', k, 0, value, 'branch_id')}
                                    value={ v.branch_id === 0 ? null : branchData.data.find((f) => f.id === v.branch_id) || null} 
                                    renderInput={(params) => (
                                        <CstmTextField {...params} label="Branch" placeholder="Branch Name" error={v.branch_id === 0 && v.error.stat}/>
                                    )}
                                />
                                <Box display={loc !== 'create' ? "flex" : "none"} alignItems="center" justifyContent="flex-end" gap={1} pt={1}>
                                    <IOSSwitch name="active" checked={v.active === 1} onChange={(e) => inputChangeHandler(e, 'main', k, 0)}/>
                                    <Box fontSize={12}>Active</Box>
                                </Box>
                            </Box>  
                            <Divider/>
                            <Box display="flex" flexDirection="column" gap={1}>
                                <Box display="flex" width="100%" gap={3} minHeight="30px">
                                    <Box width={branchProductForm.length > 1 ? "46%":"49%"} fontWeight={700}>Product Details</Box>
                                    <Box width="100%" display={loc === 'create' ? "flex" : "none"} alignItems="center" justifyContent="flex-end" pr={2}>
                                        <Box display={'flex'} alignItems="center" gap={1}>
                                            {v.product.length < 10 ?
                                                <IconButton onClick={()=>addRowSubHandler(k)} variant="contained" sx={{bgcolor:'primary.main', ':hover':{bgcolor:'#1B984F'} }} >
                                                    <NewAddRoundIcon height="14" width="14"/>
                                                </IconButton>
                                            :
                                                <Box component="strong" fontSize={12} color="red">Add Up to 10 Rows Only!</Box>
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                                <Box width="100%" display="flex" flexDirection="column" gap={3}>
                                    {v.product?.map((s,i) => (
                                        <Box key={i} width="100%" display='flex'     gap={2} border="1px solid rgba(0, 0, 0, 0.12)" borderRadius="8px" p={2}>
                                            <Box width="100%" display='flex' flexDirection="column" gap={2}>
                                                <Box width="100%" display="flex" gap={3}>
                                                    <Autocomplete   
                                                        fullWidth
                                                        options={categoryData.data}
                                                        getOptionLabel={(option) => option.name}
                                                        onChange={(e, value) => inputChangeHandler(e, 'sub', k, i, value, 'category_id')}
                                                        value={ s.category_id === '' ? null : categoryData.data.find((f) => f.id === s.category_id) || null} 
                                                        renderInput={(params) => (
                                                            <CstmTextField {...params} label="Category" placeholder="Category" error={s.category_id === 0}/>
                                                        )}
                                                    />
                                                    <Autocomplete   
                                                        disabled={s.category_id === 0 ? true : false}
                                                        fullWidth
                                                        options={s.sub_cat}
                                                        getOptionLabel={(option) => option.name}
                                                        onChange={(e, value) => inputChangeHandler(e, 'sub', k, i, value, 'sub_category_id')}
                                                        value={ s.sub_category_id === '' ? null : s.sub_cat.find((f) => f.id === s.sub_category_id) || null} 
                                                        renderInput={(params) => (
                                                            <CstmTextField {...params} label="Sub-Category" placeholder="Sub-Category" error={s.sub_category_id === 0 && s.category_id > 0 && s.prod_error.stat} />
                                                        )}
                                                    />
                                                </Box>
                                                <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" gap={3}>
                                                    <Autocomplete   
                                                        fullWidth
                                                        options={productData.data}
                                                        getOptionLabel={(option) => option.name}
                                                        onChange={(e, value) => inputChangeHandler(e, 'sub', k, i, value, 'product_id')}
                                                        value={ s.product_id === '' ? null : productData.data.find((f) => f.id === s.product_id) || null} 
                                                        renderInput={(params) => (
                                                            <CstmTextField {...params} label="Product" placeholder="Product Name" error={s.product_id === 0 || s.prod_error.product_id}/>
                                                        )}
                                                    />
                                                    <CstmTextField onInput={(e) => inputChangeHandler(e, 'sub', k, i )} fullWidth variant="outlined" label="Quantity" name="quantity" value={s.quantity} error={s.quantity === '' || s.quantity < 0.1 } />
                                                </Box>
                                                <Box width="100%" display="flex" gap={3}>
                                                    <CstmTextField onInput={(e) => inputChangeHandler(e, 'sub', k, i )} fullWidth variant="outlined" label="Price" name="price" value={s.price} error={s.price === '' || s.price < 0.1 } />
                                                    <CstmTextField onInput={(e) => inputChangeHandler(e, 'sub', k, i )} fullWidth variant="outlined" label="Discount" name="discount" value={s.discount} error={ s.discount === '' && s.prod_error.stat} />
                                                </Box>
                                            </Box>
                                            <Box minWidth="300px" display="flex" flexDirection="column" gap={1} alignItems="flex-end">
                                                {v.product.length > 1 && (
                                                    <Box display="flex" alignItems="center" justifyContent="flex-start">
                                                        <IconButton onClick={(e) => removeRowSubHandler(e, k, i, v.product)} variant="contained" sx={{bgcolor:'#D8D5D5', ':hover':{bgcolor:'#E4F0E9'} }}>
                                                            <NewRemoveRoundIcon height="14" width="14"/>
                                                        </IconButton>
                                                    </Box>
                                                )}
                                                <DropzoneWrapper index={k} subIndex={i} branchProductForm={branchProductForm} val={s} loader={loader} setLoader={setLoader} submitFormLoader={submitFormLoader} setSubmitFormLoader={setSubmitFormLoader} loc={loc}/>
                                            </Box>
                                        </Box>
                                    )).reverse()}
                                </Box>
                            </Box>
                        </Box>
                    </Collapse>
                )).reverse()}
            </TransitionGroup>    
        </Box>
    )
}