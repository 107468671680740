import { useHistory } from "react-router-dom";
import { Box, Button } from "@mui/material"
import { ArrowLeftIcons } from "../../../../../core/global/Icons"
import { CategoryCreate, CategoryUpdate } from "../../../global/API";

export const Bottom = (props) => {
    let nav = useHistory()
    const { loc, user_id, __SESSION, submitFormLoader, setSubmitFormLoader, categoryForm, setCategoryForm, loader, setLoader } = props

    const backHandler = () => {
        nav.push('/setup/category/list')
    }

    const submitHandler = async() => {
        setSubmitFormLoader((prev) => ({...prev, loader:true}))
        if (loc === 'create') {
            let data = [...categoryForm]
            for (let i = 0; i < data.length; i++) {
                let res = await CategoryCreate(__SESSION.data.uaccess[0].company_id, user_id, data[i].category_name, data[i].sub )
                if (res.msg === 'sub_name_exist') {
                    const index = data[i].sub.findIndex(item => item.sub_name === res.name);
                    const subdata = data[i].sub.filter((v, k) => { return index <= k } );
                    setSubmitFormLoader((prev) => ({...prev, loader:false}))
                    setCategoryForm(
                        (prev) => (prev.map((v,k)=> v.id === data[i].id? {...v, sub: subdata.map((s) => s.sub_name === res.name ? {...s, sub_error:{...s.sub_error, name:res.msg === 'sub_name_exist' } } : {...s} ) }:{...v} ))
                    )
                    return
                }else{  
                    setCategoryForm(
                        (prev) => (prev.filter(v=>{return v.id!==data[i].id} ))
                    )
                }
            }
        }else{
            let subfltr = categoryForm[0].sub.filter(v => { return v.id === 0 || v.edit }).sort((a, b) => b.id - a.id)
            let res = await CategoryUpdate(
                __SESSION.data.uaccess[0].company_id, 
                user_id, 
                categoryForm[0].id, 
                categoryForm[0].category_name,
                categoryForm[0].active,
                subfltr
            )
            if (res.msg === 'sub_name_exist' || res === 'name_exist') {
                setSubmitFormLoader((prev) => ({...prev, loader:false}))
                if (res === 'name_exist') {
                    setCategoryForm(
                        (prev) => (prev.map(v => { return {...v, error:{...v.error, stat:true, name:true} } }))    
                    )
                    
                }else{
                    setCategoryForm(
                        (prev) => (prev.map((v)=> v.id === categoryForm[0].id? {...v, sub: categoryForm[0].sub.map((s) => s.sub_name === res.name && s.id === 0 ? {...s, sub_error:{...s.sub_error, name:res.msg === 'sub_name_exist' } } : {...s, 
                            id:s.id === 0  ? res.ids.filter(f => {return f.name === s.sub_name}).length > 0 ? res.ids.filter(f => {return f.name === s.sub_name})[0].id : 0 : s.id, 
                            active:s.active !== undefined ? s.active : 1, remove:false, edit:false, is_default:0 } ) }:{...v} ))    
                    )
                }
                return
            }
        }

        setLoader({...loader, snackbar:true, msg:`Successfully ${loc === 'create' ? 'Added!' : 'Updated!'}`})
        setSubmitFormLoader((prev) => ({...prev, loader:false}))
        nav.push('/setup/category/list')
    }

    return (
        <Box minHeight="80px" display="flex" alignItems="center" justifyContent="flex-end" gap={2}>
            <Box onClick={backHandler} component={Button} disableFocusRipple={true} minHeight="50px" minWidth="120px" borderRadius={3} display="flex" alignItems="center" justifyContent="center" bgcolor="#fff" color="#00000061" sx={{border:"1px solid #EBEBEB"}} startIcon={<ArrowLeftIcons stroke="#00000061"/>} px={3}>
                <Box fontSize={14} fontWeight={400} lineHeight= "19px">Back</Box>
            </Box>
            <Box disabled={submitFormLoader.submit} onClick={submitHandler} component={Button} disableFocusRipple={true} minHeight="50px" minWidth="120px" borderRadius={3} display="flex" alignItems="center" justifyContent="center" bgcolor={submitFormLoader.submit?"#fff":"primary.main"} color={submitFormLoader.submit?"#00000061":"#fff"} sx={{border:"1px solid #EBEBEB", ':hover':{bgcolor:'primary.main', color:'#FFFFFF'}}} px={3}>
                <Box fontSize={14} fontWeight={400} lineHeight="19px">{loc === 'create' ? 'Save' : 'Update'}</Box>
            </Box>
        </Box>
    )
}