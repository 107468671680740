import { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material"
import { ToolContext } from "../../../../../core/context/ToolContext";
import { UomRead } from "../../../global/API";
import { Top } from "./Top";
import { Form } from "./Form";
import { Bottom } from "./Bottom";
import { BackDropLoader } from "../../loader/BackDropLoader";

export const Setup = ({loc, productList, loader, setLoader, __SESSION}) => {
    const { setup_state } = useContext(ToolContext)
    const { uomData } = setup_state
    let user_id = parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]);
    let pid = productList.data.length
    let series_num = loc === 'create' ? `PR-000000000${pid + 1}` : JSON.parse(window.atob(loc)).series_num
    let productName = loc === 'create' ? '' : JSON.parse(window.atob(loc)).name
    let description = loc === 'create' ? '' : JSON.parse(window.atob(loc)).description
    let uom_id = loc === 'create' ? 0 : JSON.parse(window.atob(loc)).uom_id
    let productActive = loc === 'create' ? 1 : JSON.parse(window.atob(loc)).active
  
    const [productForm, setProductForm] = useState([{
        id:loc === 'create' ? pid + 1 : JSON.parse(window.atob(loc)).id, 
        series_num:series_num, 
        product_name:productName,
        description:description,
        uom_id:uom_id,
        error:{stat:true, name:false, msg:'Fields Required!'},
        active:productActive,
    }])

    const [submitFormLoader, setSubmitFormLoader] = useState({submit:true, loader:false})
    
    const globalData = async() => {
        let res_uom = await UomRead()
        if (res_uom) {
            uomData.set(res_uom)
        }
    }

    useEffect(() => {
        globalData()
        //eslint-disable-next-line
    }, [])
    
    return (
        <Box height="100%" width="100%" borderRadius="12px" display="flex" flexDirection="column" justifyContent="flex-start" gap={3}>
            <Top loc={loc} pid={pid} productForm={productForm} setProductForm={setProductForm} />
            <Box minHeight="10px" my={-1.5} fontSize={10} textAlign="right" color="red">All Input Fields are Required *</Box>
            <Form loc={loc} pid={pid} productForm={productForm} setProductForm={setProductForm} submitFormLoader={submitFormLoader} setSubmitFormLoader={setSubmitFormLoader} uomData={uomData} />
            <Bottom loc={loc} user_id={user_id} __SESSION={__SESSION} loader={loader} setLoader={setLoader} productForm={productForm} setProductForm={setProductForm} submitFormLoader={submitFormLoader} setSubmitFormLoader={setSubmitFormLoader} />
            <BackDropLoader submitFormLoader={submitFormLoader} />
        </Box>
    )
}