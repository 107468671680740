import { useEffect } from "react";
import { Box, Button } from '@mui/material';
import { Sync as SyncIcon } from '@mui/icons-material';
import { SalesBranchReport } from "../../../global/API";
import { DataSetArray } from "./DataSetArray";
import moment from "moment";
import Excel from '../../../../../assets/images/icons/excel.png';
import ReactExport from 'react-data-export';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export const Generate = (props) => {
    const { __SESSION, snackBarLoader, form, generateForm, setGenerateForm, salesBranchData, setSalesBranchData, salesBranchList, setSalesBranchList } = props

    const handleGenerate = async() => {
        __generateData(0)
    }

    const __generateData = async(offset) => {
        if (form.to === '' || form.from === '' || form.to < form.from) {
            snackBarLoader.set({...snackBarLoader.data, snackbar:true, msg: `Invalid Date!`})
            return
        }else{
            setGenerateForm((prev) => ({...prev, infiniteLoad:true}))
        }
        let qsp = {
            branch_id:form.branch_id,
            company_id:__SESSION.data.uaccess[0].company_id,
            user_id:form.user_id,
            from:form.from,
            to:form.to,
            limit:generateForm.limit,
            offset:offset   
        }

        let res = await SalesBranchReport(qsp)
        if (res) {
            if (res.length === 0) {
                FormatData([...salesBranchData, ...res])
                return
            }else{
                ///// LOADER VALIDATION
                if (res.length < generateForm.limit) {
                    FormatData([...salesBranchData, ...res])
                    return
                }else{
                    setGenerateForm((prev) => ({...prev, offset:res.length + salesBranchData.length}))
                }
                ///// STATE LIST DATA
                setSalesBranchData([...salesBranchData, ...res])
            }   
        }
    }

    const FormatData = (salesBranchData) => {
        const groupedData = salesBranchData.reduce((acc, item) => {
            const { date, id, total_sales, created_at, company_branch_id, actual_sales, variance, remarks } = item;
            const key = `${id}-${date}-${company_branch_id}`;
        
            if (!acc[key]) {
              acc[key] = {...item,
                remarks:remarks===null? '': remarks,
                variance:variance === null ? 0 : variance,
                actual_sales:actual_sales === null ? 0 : actual_sales,
                total_sales: parseFloat(total_sales) || 0,
                date : id === null ? moment(created_at).format("YYYY-MM-DD") : date
              };
            } else {
              acc[key].total_sales += parseFloat(total_sales) || 0;
            }
        
            return acc;
          }, {});
        
        const result = Object.values(groupedData);
        setSalesBranchList(result)
        setGenerateForm((prev) => ({...prev, infiniteLoad:false, offset:0}))
        setSalesBranchData([])
    }

    useEffect(() => {
        if (generateForm.offset !== 0) {
            __generateData(generateForm.offset)
        }
        //eslint-disable-next-line
    }, [salesBranchData])

    return (
        <Box minHeight="55px" display="flex" justifyContent="flex-end" alignItems="center" gap={2}>
            {salesBranchList.length > 0 && (
                <ExcelFile filename={`Sales_Branch_Reports_${moment(new Date()).format('MM/DD/YYYY')}`}
                    element={
                        <Box display="flex" alignItems="center" className="c-pointer" bgcolor="#FFFFFF" borderRadius={2} padding="12px 16px">
                            <img src={Excel} alt="excel-img" />
                            <Box ml="10px" color="#1D6F42">Export in excel</Box>
                        </Box>
                    }
                >
                    <ExcelSheet dataSet={DataSetArray(salesBranchList)} name="Reports" />
                </ExcelFile>
            )}
            <Box onClick={handleGenerate} component={Button} disableFocusRipple={true} minHeight="50px" minWidth="130px" borderRadius={3} display="flex" alignItems="center" justifyContent="center" bgcolor="primary.main" color="#fff" sx={{border:"2px solid #EBEBEB", ':hover':{bgcolor:'primary.main', color:'#FFFFFF'}}} startIcon={<SyncIcon/>}>
                <Box fontSize={16} fontWeight={400} lineHeight="19px">Generate</Box>
            </Box>
        </Box>
    )
}