import { Box, Button, Grid, InputBase } from "@mui/material"
import { AddRoundIcon, EditProfile, SearchIcons } from "../../../../../core/global/Icons"
import { useHistory } from "react-router-dom"
import { PrimaryToolTip } from "../../../../../core/global/ToolTip"
import { useContext, useEffect, useState } from "react"
import { ToolContext } from "../../../../../core/context/ToolContext"
import { SnakeLoader } from "../../../../../core/loader/SnakeLoader"
import { Waypoint } from "react-waypoint"
import { CstmSnackBar } from "../../../../../core/global/forms/CstmSnackBar"
import { NoDataFound } from "../../../empty/Index"
import { ListLoader } from "../../loader/ListLoader"
import { BranchUserRead, ToolUser } from "../../../global/API"
import { removeDuplicates } from "../../../../../core/validator/Validator"
import { WarningModal } from "../../../../../core/global/modal/WarningModal"
import { domain } from "../../../../../core/Env"

export const List = ({loader, setLoader}) => {
    const nav = useHistory()
    const { tool_state, setup_state } = useContext(ToolContext)
    const { __SESSION } = tool_state
    const { branchUserList, branchUserSearchList, searchInput, listLoader, infiniteState, toolUser } = setup_state

    const redirectionHandler = async(type, value) => {
        infiniteState.set((prev) => ({...prev, offset:0}))
        setLoader({...loader, snackbar:false, msg:''})
        await branchUserList.set([])
        await branchUserSearchList.set([])
        nav.push(`/setup/branch-user/${type === 'create' ? type : window.btoa(JSON.stringify(value))}`)
    }

    const loadMoreData = async() => {
        if (infiniteState.data.offset > 0) {
            __init(infiniteState.data.offset)
        }
    }

    const __init = async(offset=0) => {
        if (offset === 0) {
            listLoader.set((prev) => ({...prev.data, isloading:true}))
            infiniteState.set((prev) => ({...prev, infiniteLoad:true}))
        }
        let qsp = {
            company_id: __SESSION.data.uaccess[0].company_id, 
            filter:'all',
            limit:infiniteState.data.limit,
            offset:offset,
            search:searchInput.data
        }

        let res = await BranchUserRead(qsp)
        if (res) {
            if (res.length === 0) {
                infiniteState.set((prev) => ({...prev, infiniteLoad:false, offset:0}))
            }else{
                ///// LOADER VALIDATION
                if (res.length < infiniteState.data.limit) {
                    infiniteState.set((prev) => ({...prev, infiniteLoad:false, offset:0}))
                }else{
                    infiniteState.set((prev) => ({...prev, offset:res.length + branchUserList.data.length}))
                }
                ///// STATE LIST DATA
                if (searchInput.data === '') {
                    branchUserList.set([...branchUserList.data, ...res])
                }else{
                    branchUserSearchList.set([...branchUserSearchList.data, ...res])
                }
            }   
            listLoader.set((prev) => ({...prev.data, isloading:false}))
        }
    }

    const searchHandler = () => {
        __init(0)
        listLoader.set((prev) => ({...prev.data, isloading:false}))
    }

    useEffect(() => {
        if (!listLoader.data.isloading) {
            listLoader.set((prev) => ({...prev.data, isloading:true}))
        }
        branchUserList.set([])
        branchUserSearchList.set([])
        const searchFcn = setTimeout(() => {
            if (searchInput.data === '') {
                __init(0)
                listLoader.set((prev) => ({...prev.data, isloading:false}))
            }else{
                searchHandler()
            }
        }, 500);
        return () => clearTimeout(searchFcn)
        //eslint-disable-next-line
    }, [searchInput.data])

    const [loaderModal, setLoaderModal] = useState(true)

    const getGlobalData = async() => {
        setLoaderModal(true)
        let res_user = await ToolUser(__SESSION.data.uaccess[0].company_id)
        if (res_user.users.length > 0) {
            let data = res_user.users.filter(v=>{return v.active === 1})
            const uniqueData = removeDuplicates(data);
            if (res_user) toolUser.set(uniqueData) 
        }else{
            setLoaderModal(false)
        }
    }

    useEffect(() => {
        getGlobalData()
        //eslint-disable-next-line
    }, [])

    const redirectBiz = () => {
        try {
            window.open(`${domain('biz').url}/sso/account/auth?ainfo=${encodeURI(JSON.stringify(__SESSION.data.ainfo))}&ref=general-maintenance`, '_blank').focus()
        } catch (error) {
            window.location.href = `${domain('biz').url}/sso/account/auth?ainfo=${encodeURI(JSON.stringify(__SESSION.data.ainfo))}&ref=general-maintenance`;
        }
    }

    return (
        <Box height="100%" width="100%" bgcolor="#FFFFFF" borderRadius="12px" display="flex" flexDirection="column" p={3}>
            <Box minHeight="120px" display="flex" flexDirection="column" gap={3}>
                <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
                    <InputBase
                        onInput={(e)=> searchInput.set(e.target.value)}
                        placeholder="Search"
                        inputProps={{ 'aria-label': 'search' }} endAdornment={ <Box pr={1} pt={1}><SearchIcons /></Box> }
                        sx={{backgroundColor: "#FFFFFF", border: "#D9EBE8 solid 2px", borderRadius: '10px', padding: '5px 5px 5px 20px', width: '291px', height: '49px'}}
                    />
                    <Box onClick={()=>redirectionHandler('create')} component={Button} disableFocusRipple={true} minHeight="50px" borderRadius={3} display="flex" alignItems="center" justifyContent="center" bgcolor="primary.main" color="#fff" sx={{border:"2px solid #EBEBEB", ':hover':{bgcolor:'primary.main', color:'#FFFFFF'}}} startIcon={<AddRoundIcon/>} px={3}>
                        <Box fontSize={16} fontWeight={400} lineHeight="19px">Branch User</Box>
                    </Box>
                    
                </Box>
                <Grid container direction="row" sx={{px:2}}>
                    <Grid item xs={4}>Branch</Grid>
                    <Grid item xs={4}>User</Grid>
                    <Grid item xs={2} textAlign="center">Status</Grid>
                    <Grid item xs={2} textAlign="center">Action</Grid>
                </Grid>
            </Box>

            <Box height="100%" width="100%" className="overflowY" display="flex" flexDirection="column" gap="12px">
            {listLoader.data.isloading && <ListLoader/>}
            {(!infiniteState.data.infiniteLoad && !listLoader.data.isloading && ( searchInput.data === '' ? branchUserList.data.length === 0 : branchUserSearchList.data.length === 0 ) ) && <NoDataFound/>}
            {!listLoader.data.isloading && ( searchInput.data === '' ? branchUserList.data: branchUserSearchList.data ).map((v,k) => (
                <Grid key={k} container direction="row" sx={{p:2, border:'1px solid #EBEBEB', borderRadius:'10px'}}>
                    <Grid item xs={4}>{v.branch_name}</Grid>
                    <Grid item xs={4}>{v.first_name} {v.last_name}</Grid>
                    <Grid item xs={2} textAlign="center">{v.active === 1 ?"Active":"Inactive"}</Grid>
                    <Grid item xs={2} textAlign="center">
                        <PrimaryToolTip onClick={()=>redirectionHandler('edit', v)} title="Update" arrow sx={{margin: '0 14px 0 14px'}} placement="top">
                            <Box fontWeight={500} className="c-pointer">
                                <EditProfile fill={'none'} stroke={'#292d32'}/>
                            </Box>
                        </PrimaryToolTip>
                    </Grid>
                </Grid>
            ))}
            {(infiniteState.data.infiniteLoad && !listLoader.data.isloading) && (
                    <Grid item xs={12} container direction="row" justifyContent="center">
                        <Waypoint onEnter={loadMoreData} >
                            <Box component="strong" mt={5} display="flex" justifyContent="center">
                                <SnakeLoader size="10px" bg="#11783C" distance="0.3rem" />
                            </Box>
                        </Waypoint>
                    </Grid>
                )}
            </Box>
            {loader.snackbar && (
                <CstmSnackBar value={loader} setter={setLoader} msg={loader.msg} type='success' /> 
            )}
            {(toolUser.data.length === 0 && !loaderModal) && (
                <WarningModal open={true} text="Set Up Users in General Maintenance in POFSIS Business" subtext="Complete the General Maintenance setup to ensure assigned users appear in the POS setup." link={redirectBiz}/>
            )}
        </Box>
    )
}