import { Box, IconButton } from "@mui/material"
import { NewAddRoundIcon } from "../../../../../core/global/Icons"

export const Top = (props) => {
    const { loc, bid, submitFormLoader, setSubmitFormLoader, branchForm, setBranchForm } = props

    const addRowHandler = () => {
        setSubmitFormLoader({...submitFormLoader, submit:true})
        setBranchForm([...branchForm, {id:bid + branchForm.length + 1, code:'', name:'', active:1, error:{stat:true, code:false, name:false, msg:'Fields Required!'} }])
    }

    return (
        <Box minHeight="80px" bgcolor="#ECECEC" borderRadius="10px" display="flex" alignItems="center" justifyContent="space-between" px={3}>
            <Box component="strong" fontSize={16}>{loc === 'create' ? 'Add' : 'Update' } Branch</Box>
            {loc === 'create' && (
                (branchForm.length < 5 ?
                    <IconButton onClick={addRowHandler} variant="contained" sx={{bgcolor:'primary.main', ':hover':{bgcolor:'#1B984F'} }} >
                        <NewAddRoundIcon/>
                    </IconButton>
                :
                    <Box component="strong" fontSize={12} color="red">Add Up to 5 Rows Only!</Box>
                )
            )}
        </Box>
    )
}