import { Alert, Slide, Snackbar } from "@mui/material"
import { useState } from "react"

export const CstmSnackBar = (props) => {
    const { value, setter, msg, type } = props;
 
    const [alrt] = useState({vertical:'bottom', horizontal:'center'}) 
    const { vertical, horizontal } = alrt;

    const HandleClose = () => {
        setter({...value, snackbar:false, msg:''})
    }
    
    return (
        <Snackbar key={vertical + horizontal} anchorOrigin={{ vertical, horizontal }} open={value.snackbar} onClose={HandleClose} 
        autoHideDuration={1500}
        >
           <Slide in={value.snackbar} direction='up' sx={{padding:'0px 8px 0px 8px', color:'#fff'}}>
                <Alert
                    severity={type}
                    variant="filled"
                >
                    {msg}
                </Alert>
           </Slide>
        </Snackbar>
    )
}