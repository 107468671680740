import { useHistory, useLocation } from 'react-router-dom';
import { Avatar, Backdrop, Box, Button, CircularProgress, Divider, List, ListItemButton, Stack } from '@mui/material';
import { useContext } from 'react';
import { ToolContext } from '../../../core/context/ToolContext';
import { SupportIcons, LogoutIcons } from '../../../core/global/Icons';
import PSLLogo from '../../../assets/images/core/avatar2.png'
import { db } from '../../../db/db';

export const Settings = () => {
    const __LOCATION = useLocation().pathname
    const nav = useHistory()
    const { tool_state, install_state, navigation_state, session_state, product_state } = useContext(ToolContext)
    const { __SESSION } = tool_state
    const { sessionData, navSession } = session_state
    const { productViewList } = product_state
    const display = __SESSION.data.pinfo.length > 0 ? __SESSION.data.pinfo[0].profile !== null ? JSON.parse(__SESSION.data.pinfo[0].profile).profile !== null ? JSON.parse(__SESSION.data.pinfo[0].profile).profile : PSLLogo : PSLLogo : PSLLogo
    const pinfo = tool_state.__SESSION.data.pinfo[0]

    const handleClick = async(e, name) => {
        if (name === 'Get App for Desktop' || name === 'Get App for Mobile') {
            install_state.ip.set({stat: true, prompt_type: 'install'})
        }else if(name === 'Support'){
            window.chaport.open();
        }else if(name === 'Tool Categories'){
            nav.push('/tool-categories')
        }
    }

    const logoutHandler = async () => {
        // install_state.ip.set({stat: true, prompt_type: 'logout'})
        // return
        if (install_state.prompt.data !== null && !('serviceWorker' in navigator && window.matchMedia('(display-mode: standalone)').matches)) {
            install_state.ip.set({stat: true, prompt_type: 'logout'})
        } else {
            await dataRemove()
            localStorage.removeItem('pos_biz_session')
            window.location.href = '/'
        }
    }

    const signoutHandler = async () => {
        await dataRemove()
        nav.push('/')
    }

    const dataRemove = () => {
        productViewList.set([])
        navSession.set((prev) => ({...prev, isShow:false}))
        sessionData.set((prev) => ({...prev, modal:true, isShow:true, branch_id:0, branch_name:''}))
        db.store_session.delete(sessionData.data.id)
    }

    return (
        <Box width="100%" height="100%" display="flex" flexDirection="column" gap="37px" bgcolor="#FFFFFF">
            <Backdrop open={install_state.ip.data.stat} sx={{zIndex: '1301'}}>
                <Box height="150px" width="100%" display="flex" alignItems="center" justifyContent="center">
                    <Box height="100%" width="270px" borderRadius="12px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={3}>
                        <Stack sx={{ color: 'grey.300' }} spacing={2} direction="row">
                            <CircularProgress color="inherit"  />
                        </Stack>
                        <Box color="#fff">Please wait while saving...</Box>
                    </Box>
                </Box>
            </Backdrop>

            <Box component={Button} onClick={()=>nav.push('/profile')} width="100%" height="91px" py="12px" px="16px" display="flex" justifyContent="flex-start" alignItems="center" gap="16px">
                <Avatar src={display} sx={{width:'56px', height:'56px'}}/>
                <Box display="flex" flexDirection="column" alignItems="flex-start" gap="4px" color="#283745">
                    {__SESSION.data.pinfo.length === 0 ?
                        <Box fontSize="14px" fontWeight={600} lineHeight="23px">Hero User</Box>
                    :
                        <Box fontSize="14px" fontWeight={600} lineHeight="23px">{pinfo.first_name} {pinfo.last_name}</Box>
                    }
                    <Box fontSize="11px" fontWeight={400} lineHeight="19px">{tool_state.__SESSION.data.ainfo.email}</Box>
                    <Box fontSize="11px" fontWeight={400} lineHeight="19px">{tool_state.__SESSION.data.uaccess[0].role_id === 0 ? 'Business Account' : 'Work Account'}</Box>
                </Box>
            </Box>
            <Box height="100%" className="overflowY cstmscroll">
                <List>
                    {
                        navigation_state.tabs.data.msetting.map((t, k) => (
                            <Box key={k} height="48px" fontSize="14px" fontWeight={400} color="#283745" lineHeight="23px">
                                <ListItemButton onClick={(e)=>handleClick(e, t.name)}  sx={{px:'16px !important', display:'flex', justifyContent:"space-between", alignItems:'center', gap:'12px'}} >
                                    <Box display="flex" alignItems="center" gap="12px">
                                        {__LOCATION.split('/')[2] === t.ref ? t.active : t.not_active}
                                        <Box color={t.name === 'Refer a Hero Users' ? '#A2A3A9' : "#283745"}>{t.name}</Box>
                                    </Box>
                                </ListItemButton>
                            </Box>
                        ))
                    }
                </List>
            </Box>
            <Box px="16px">
                <Divider sx={{height:'1px', bgcolor:'#C9D6DF', p:'0px !important'}} />
            </Box>
            <Box height="144px" >
                <Box height="48px" fontSize="14px" fontWeight={400} color="#283745" lineHeight="23px">
                    <ListItemButton onClick={(e)=>handleClick(e, 'Support')} sx={{px:'16px !important', display:'flex', alignItems:'center', gap:'12px'}} >
                        <SupportIcons fill={'#89A594' } width="20" height="20"/>
                        Support
                    </ListItemButton>
                </Box>
                <Box display={sessionData.data.branch_name === '' ? 'none':'block'} height="48px" fontSize="14px" fontWeight={400} color="#283745" lineHeight="23px">
                    <ListItemButton onClick={(e)=>signoutHandler(e)} sx={{px:'16px !important', display:'flex', alignItems:'center', gap:'12px'}} >
                        <LogoutIcons/>
                        Sign-out {sessionData.data.branch_name} Branch
                    </ListItemButton>
                </Box>
                <Box height="48px" fontSize="14px" fontWeight={400} color="#283745" lineHeight="23px">
                    <ListItemButton onClick={(e)=>logoutHandler(e)} sx={{px:'16px !important', display:'flex', alignItems:'center', gap:'12px'}} >
                        <LogoutIcons/>
                        Logout Account
                    </ListItemButton>
                </Box>
            </Box>
            {install_state.prompt.data !== null && (
                <Box minHeight="64px"></Box>
            )}
        </Box>
    )
}