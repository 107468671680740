import { Box, Button, Modal, Slide, useMediaQuery, } from "@mui/material";
import { WarningAmber } from "@mui/icons-material";

export const WarningModal = ({open, text, subtext, link=null}) => {
    const sm = useMediaQuery('(max-width:500px)')
    return (
        <Modal open={open}
            sx={{width: '100%', height:'100%'}}
            className="noutlined"
        >
            <Slide in={open} direction="up" className="noutlined" >
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center" px={sm && 2}>
                    <Box width={sm ? '100%' : '456px'} bgcolor="#FFFFFF" px="24px" pb="24px" borderRadius="6px">
                        <Box minHeight="52px"></Box>
                        <Box display="flex" flexDirection="column" gap="20px">
                            <WarningAmber/>
                            <Box color="#292C2F" fontSize={16} fontWeight={600} lineHeight="20px" >{text}</Box>
                            <Box color="#5B6167" fontSize={14} fontWeight={400} lineHeight="21px" >
                                {subtext}
                            </Box>
                            {link !== null && (
                                <Box fontSize={14} fontWeight={400} lineHeight="21px" width="100%" >
                                    <Button onClick={link} variant="contained" sx={{py:1, borderRadius:'8px'}} fullWidth>Go to General-Maintenance</Button>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Slide>
        </Modal>
    )
}