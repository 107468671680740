import { useContext, useEffect, useState } from "react"
import { Box, Button, useMediaQuery } from "@mui/material"
import { ToolContext } from "../../../../core/context/ToolContext";
import { CstmTextField } from "../../../../core/global/forms/CstmTextField";
import { SalesReportRead, SalesReportSubmit } from "../../global/API";
import { Loader } from "./Loader";
import { ConfirmationWarning } from "./modal/Confirmation";
import { Warning } from "./Warning";
import { isNumeric } from "../../../../core/global/Format";
import moment from "moment";

export const Index = () => { 
    const { tool_state, session_state, report_state } = useContext(ToolContext)
    const { __SESSION: {data: {ainfo, pinfo}} } = {...tool_state}
    const { sessionData, navSession } = session_state
    const { eodSales } = report_state
    let user_id = parseInt(atob(ainfo.aid).split('_', 2)[1]);

    const [form, setForm] = useState({remarks:''})
    const [submitFormLoader, setSubmitFormLoader] = useState({submit:true, modal:false, loader:false})
    const [isLoading, setIsLoading] = useState(true)
    const sm = useMediaQuery('(max-width:500px)')

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === 'remarks') {
            let data = {...form}    
            data[name] = value
            setForm(data)
        }else{
            let data = {...eodSales.data}
            let numeric = isNumeric(value)? 'integer' : value.includes('.') ? 'integer':'string'
            const regex = /^[0-9]*\.?[0-9]*$/;
            if (regex.test(value)) {
                data[name] = value
                data['variance'] = numeric === 'integer' && parseFloat((value) - eodSales.data.total_sales).toFixed(2)
            }
            setSubmitFormLoader({...submitFormLoader, submit:!parseInt(data['actual_sales']) > 0 || data['actual_sales'] === '.'})
            eodSales.set(data)
        }   
        
    }

    const submitHandler = async(type) => {
        if (type === 'submit') {
            setSubmitFormLoader({...submitFormLoader, modal:true})
            return
        }

        if (type === 'cancel') {
            setSubmitFormLoader({...submitFormLoader, modal:false})
            return
        }else{
            setSubmitFormLoader({...submitFormLoader, loader:true})
            setIsLoading(true)
            let body = {
                branch_id:sessionData.data.branch_id,
                user_id:user_id,
                sales:eodSales.data.actual_sales,
                variance:eodSales.data.variance,
                remarks:form.remarks
            }
            let res = await SalesReportSubmit(body)
            if (res === 'success') {
                navSession.set((prev) => ({...prev, isShow:true}))
                setIsLoading(false)
                eodSales.set((prev) => ({...prev, eod:eodSales.data.actual_sales, actual_sales:0}))
                setSubmitFormLoader({...submitFormLoader, modal:false, loader:false})
            }
        }
    }

    const __init = async() => {
        eodSales.set((prev) => ({...prev, total_sales:0, actual_sales:0, eod:0}))
        setIsLoading(true)
        let qsp = {
            filter:'per_user',
            branch_id:sessionData.data.branch_id,
            user_id:user_id
        }
        let res = await SalesReportRead(qsp)
        if (res) {
            setForm({...form, remarks: res.remarks === null ? '' : res.remarks})
            eodSales.set((prev) => ({...prev, total_sales:res.total_sales, eod:res.eod_sales}))
            setIsLoading(false)
        }
    }

    useEffect(() => {
        __init()
        // eslint-disable-next-line
    }, [])
   
    return (
        <Box height="100%" width="100%" display="flex" flexDirection="column" px={1.5}>
            <Box minHeight="10px"></Box>
            { sessionData.data.modal ? 
                <Warning/>
            :
                isLoading? 
                    <Loader/>
                :
                    <Box height="auto" width="100%" bgcolor="#FFFFFF" borderRadius="4px" p={2} display="flex" flexDirection="column" gap={1}>
                        <Box width="100%" textAlign="center" fontWeight={700}>
                            End of Day Sales {eodSales.data.eod !== 0 ? 'Report' : 'Entry'}
                        </Box>
                        <Box width="100%" display="flex" flexDirection="column" gap={1} py={2}>
                            <Box width="100%" display="flex" gap={2} >
                                <Box fontSize={14} fontWeight={400}> Branch Name :  </Box>
                                <Box fontSize={14} fontWeight={700}> {sessionData.data.branch_name} Branch </Box>
                            </Box>
                            <Box width="100%" display="flex" gap={2} >
                                <Box fontSize={14} fontWeight={400}> Person in charge :  </Box>
                                <Box fontSize={14} fontWeight={700}> {pinfo.length === 0 ? 'Hero' : `${pinfo[0].first_name} ${pinfo[0].last_name}` } </Box>
                            </Box>
                            <Box width="100%" display="flex" gap={2}>
                                <Box fontSize={14} fontWeight={400}> Date :  </Box>
                                <Box fontSize={14} fontWeight={700}> {moment(new Date()).format("MM-DD-YYYY")} </Box>
                            </Box>
                        </Box>
                        {eodSales.data.eod !== 0 ? 
                            <>
                                <Box width="100%" display="flex" flexDirection="column" gap={1}>
                                    <Box fontSize={14} fontWeight={400}>Total Sales:</Box>
                                    <CstmTextField fullWidth variant="outlined"
                                        value={`₱ ${eodSales.data.total_sales}`}
                                        inputProps={{min: 0, style: { textAlign: 'right' }}} 
                                    />
                                </Box>
                                <Box width="100%" display="flex" flexDirection="column" gap={1}>
                                    <Box fontSize={14} fontWeight={400}>Actual Sales:</Box>
                                    <CstmTextField fullWidth variant="outlined"
                                        value={`₱ ${eodSales.data.eod}`}
                                        inputProps={{min: 0, style: { textAlign: 'right' }}} 
                                    />
                                </Box>
                                <Box width="100%" display="flex" justifyContent="space-between" gap={1} pr="9px" py={2}>
                                    <Box fontSize={14} fontWeight={700}>Variance:</Box>
                                    <Box fontWeight={700}> {eodSales.data.eod !== parseInt(eodSales.data.total_sales) && (eodSales.data.eod > parseInt(eodSales.data.total_sales) ? 'Over +' : 'Short -')}  ₱ {parseFloat(Math.abs(eodSales.data.eod - eodSales.data.total_sales)).toFixed(2)}</Box>
                                </Box>
                                <Box fontSize={14} fontWeight={400} color="red">Notes:</Box>
                                <CstmTextField fullWidth variant="outlined"
                                    multiline
                                    name="remarks"
                                    value={form.remarks}
                                    inputProps={{ maxLength: 2400, readOnly: true }} minRows={10} maxRows={10} 
                                />
                            </>
                        :
                            <>
                                <Box width="100%" display="flex" flexDirection="column" gap={1}>
                                    <Box fontSize={14} fontWeight={400}>Sales Entry:</Box>
                                    <CstmTextField fullWidth variant="outlined"
                                        onInput={(e)=>handleChange(e)}
                                        name="actual_sales"
                                        value={eodSales.data.actual_sales}
                                        inputProps={{min: 0, style: { textAlign: 'right' }}} 
                                        error={eodSales.data.actual_sales === '.'}
                                    />
                                    <Box fontSize={14} fontWeight={400} color="red">Notes:</Box>
                                    <CstmTextField fullWidth variant="outlined"
                                        onInput={(e)=>handleChange(e)}
                                        multiline
                                        name="remarks"
                                        value={form.remarks}
                                        inputProps={{ maxLength: 2400 }} minRows={10} maxRows={10} 
                                    />
                                </Box>
                                <Box minHeight="80px" width="100%" py={2}>
                                    <Box disabled={submitFormLoader.submit} onClick={()=>submitHandler('submit')} component={Button} disableFocusRipple={true} height="100%" width="100%" borderRadius={3} display="flex" alignItems="center" justifyContent="center" bgcolor={submitFormLoader.submit?"#fff":"primary.main"} color={submitFormLoader.submit?"#00000061":"#fff"} sx={{border:"1px solid #EBEBEB", ':hover':{bgcolor:'primary.main', color:'#FFFFFF'}}} px={3}>
                                        <Box fontSize={14} fontWeight={400} lineHeight="19px">Submit</Box>
                                    </Box>
                                </Box>
                            </>
                        }
                    </Box>
            }
            <ConfirmationWarning sm={sm} submitFormLoader={submitFormLoader} submitHandler={submitHandler} eodSales={eodSales.data}/>
        </Box>
    )
}