import { useContext } from "react"
import { List } from "./list/Index"
import { Setup } from "./setup/Index"
import { ToolContext } from "../../../../core/context/ToolContext"

export const Branch = ({match}) => {
    const { setup_state, tool_state } = useContext(ToolContext)
    const { branchList, snackBarLoader } = setup_state
    const { __SESSION } = tool_state
    let loc = match.params.params
    
    if (loc === 'list') {
        return <List loader={snackBarLoader.data} setLoader={snackBarLoader.set} __SESSION={__SESSION}/>
    }else{
        return <Setup loc={loc} branchList={branchList} loader={snackBarLoader.data} setLoader={snackBarLoader.set} __SESSION={__SESSION}/>
    }
}