import { TransitionGroup } from 'react-transition-group';
import { Autocomplete, Box, Collapse, IconButton } from "@mui/material"
import { NewRemoveRoundIcon } from "../../../../../core/global/Icons"
import { CstmTextField } from "../../../../../core/global/forms/CstmTextField";
import { IOSSwitch } from "../../../../../core/global/forms/CstmToggle";

export const Form = (props) => {

    const {loc, buid, branchUserForm, setBranchUserForm, submitFormLoader, setSubmitFormLoader, branchData, toolUser} = props

    const removeRowHandler = (e, index) => {
        let data = [...branchUserForm]
        data.splice(index, 1)
        data = data.map((d, k) => ({...d, series_num:`BUSR-000000000${buid + k + 1}`}))
        setBranchUserForm(data)
    }

    const inputChangeHandler = (e, index, val, key) => {
        let name
        let value
        if (key === '' ) {
            name = e.target.name
            value = e.target.value
        }else{
            name = key;
            value = (val === null ? 0 : (val.id > 0 ? val.id:val));
        }
        
        const data = [...branchUserForm];
        data[index][name] = name === 'active' ? e.target.checked ? 1 : 0 : value  
        data[index]['error'] = {...data[index]['error'], stat: data[index]['branch_id'] === 0 ? true : false } 

        if (loc !== 'create') {
            data[0]['error'] = {...data[0]['error'], stat:data[0]['branch_id'] === 0 || data[0]['user_id'] === 0}
        }

        setBranchUserForm(data)
        const allTrue = data.some(item => item.error.stat) ? 1 : 0;
        setSubmitFormLoader({...submitFormLoader, submit:allTrue===0?false:true})
    }

    return (
        <Box height="auto" className="overflowY">
            <TransitionGroup style={{display:'flex', flexDirection:'column', gap:'16px'}}>
                {branchUserForm?.map((v,k) => (
                    <Collapse key={k}>
                        <Box minHeight="100px" bgcolor="#ffffff" borderRadius="10px" display="flex" flexDirection="column" justifyContent="center" gap={2} p={3}>
                            <Box display="flex" alignItems="center" gap={3}>
                                <Autocomplete   
                                    fullWidth
                                    options={branchData.data}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(e, value) => inputChangeHandler(e, k, value, 'branch_id')}
                                    value={ v.branch_id === 0 ? null : branchData.data.find((f) => f.id === v.branch_id) || null} 
                                    renderInput={(params) => (
                                        <CstmTextField {...params} label="Branch" placeholder="Branch Name" error={(v.branch_id === 0 && v.error.stat) || v.error.name}/>
                                    )}
                                />
                                <Autocomplete   
                                    fullWidth
                                    options={toolUser.data}
                                    getOptionLabel={(option) => (option.first_name +' '+ option.last_name)}
                                    key={option => option.id}
                                    onChange={(e, value) => inputChangeHandler(e, k, value, 'user_id')}
                                    value={ v.user_id === 0 ? null : toolUser.data.find((f) => f.id === v.user_id) || null} 
                                    renderOption={(props, option) => {
                                        return (
                                        <li {...props} key={option.id}>
                                            {option.first_name +' '+ option.last_name}
                                        </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <CstmTextField {...params} label="User" placeholder="User Name" error={(v.user_id === 0 && v.error.stat) || v.error.name}/>
                                    )}
                                />
                                {branchUserForm.length > 1 && (
                                    <IconButton onClick={(e) => removeRowHandler(e, k)} variant="contained" sx={{bgcolor:'#D8D5D5', ':hover':{bgcolor:'#E4F0E9'} }}>
                                        <NewRemoveRoundIcon/>
                                    </IconButton>
                                )}
                            </Box>
                            {loc !== 'create' && (
                                <Box display="flex" alignItems="center" justifyContent="flex-end" gap={1}>
                                    <IOSSwitch name="active" checked={v.active === 1} onChange={(e) => inputChangeHandler(e, k, '', '')}/>
                                    <Box fontSize={12}>Active</Box>
                                </Box>
                            )}
                        </Box>
                    </Collapse>
                )).reverse()}
            </TransitionGroup>    
        </Box>
    )
}