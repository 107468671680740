import { Box, useMediaQuery } from "@mui/material"
import { WarningAmber } from '@mui/icons-material';

export const Warning = () => { 
    const sm = useMediaQuery('(max-width:500px)')
  
    return (
        <Box width={sm ? '100%' : '456px'} bgcolor="#FFFFFF" px="24px" pb="24px" borderRadius="6px">
            <Box minHeight="52px"></Box>
            <Box display="flex" flexDirection="column" gap="20px">
                <WarningAmber/>
                <Box color="#292C2F" fontSize={16} fontWeight={600} lineHeight="20px" >You currently haven't selected a branch.</Box>
                <Box color="#5B6167" fontSize={14} fontWeight={400} lineHeight="21px" >
                    Please select the branch at the home tab to encode the end-of-day sales.
                </Box>
            </Box>
        </Box>
    )
}
