import { useContext, useState } from 'react';
import { useHistory } from "react-router-dom"
import { Box, Button, Container } from '@mui/material';
import { ToolContext } from '../../../../core/context/ToolContext';
import { Unavailable } from '../../../../core/global/UnAvailable';
import { Index as BranchProduct } from './branchProduct/Index';
import { Index as BranchSales } from './branchSales/Index';

export const Index = ({match}) => {
    let loc = match.params.features
    const { tool_state, } = useContext(ToolContext)
    const { __SESSION: {data: {uaccess}} } = {...tool_state}

    const nav = useHistory()
    const [tabs] = useState([
        {name:'Branch Product', ref:'branch-product'},
        {name:'Branch Sales', ref:'branch-sales'}
    ])

    const redirectHandler = async(ref) => {
        if (ref !== loc) {
            nav.push(`/reports/${ref}`)        
        }
    }
   
    return (
        <Box display="flex" height="100%" width="100%" px={3}>
            {(uaccess[0].role_name === 'Regular User' || uaccess[0].role_name === 'Supervisor') ? 
                <Unavailable type="desktop"/>
            :
                <Box component={Container} width="100%" height="100%"  display="flex" flexDirection="column" py={3}>
                    <Box minHeight="50px">
                        <Box fontSize={20} fontWeight={500}>Reports</Box>
                    </Box>
                    <Box minHeight="70px" display="flex" flexDirection="column">
                        <Box minHeight="48px" display="grid" gridTemplateColumns="repeat(auto-fill,minmax(150px,1fr))" gap="8px">
                            {tabs?.map((v, k) => (
                                <Box onClick={()=>redirectHandler(v.ref)} key={k} component={Button} disableFocusRipple={true} minWidth="150px" borderRadius={3} display="flex" flexDirection="column" alignItems="center" gap="12px" bgcolor={loc === v.ref ?"primary.main":"#FFFFFF"} color={loc === v.ref ?"#fff":"#283745"} sx={{border:"2px solid #EBEBEB", ':hover':loc === v.ref && {bgcolor:'primary.main', color:'#FFFFFF'}}} >
                                    <Box fontSize={12} fontWeight={400} lineHeight="19px" width="100%" sx={{wordBreak: 'break-word'}}>{v.name}</Box>
                                </Box>
                            ))}
                        </Box>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} py={1}></Box>
                    </Box>
                    <Components loc={loc} match={match}/>
                    <Box minHeight="5px"></Box>
                </Box>
            }
        </Box>
    )
}

const Components = ({loc, match}) => {
    if (loc === 'branch-product') { return <BranchProduct match={match}/>
    }else if (loc === 'branch-sales') { return <BranchSales match={match}/>
    }else{return 'NOT FOUND'}
}