import { useHistory } from "react-router-dom";
import { Box, Button } from "@mui/material"
import { ArrowLeftIcons } from "../../../../../core/global/Icons"
import { BranchCreate, BranchUpdate } from "../../../global/API";

export const Bottom = (props) => {
    let nav = useHistory()
    const { loc, user_id, __SESSION, loader, setLoader, branchForm, setBranchForm, submitFormLoader, setSubmitFormLoader } = props

    const backHandler = () => {
        nav.push('/setup/branch/list')
    }

    const submitHandler = async() => {
        setSubmitFormLoader((prev) => ({...prev, loader:true}))
        if (loc === 'create') {
            let data = [...branchForm]
            for (let i = 0; i < data.length; i++) {
                let res = await BranchCreate(__SESSION.data.uaccess[0].company_id, user_id, data[i].code, data[i].name )
                if (res === 'code_exist' || res === 'name_exist') {
                    setBranchForm(
                        (prev) => (prev.map(v=>v.id===data[i].id ? {...v, error:{...v.error, code:res === 'code_exist', name:res === 'name_exist' }} : {...v} ))
                    )
                    setSubmitFormLoader((prev) => ({...prev, loader:false}))
                    return
                }else{
                    setBranchForm(
                        (prev) => (prev.filter(v=>{return v.id!==data[i].id} ))
                    )
                }
            }

        }else{
            let res = await BranchUpdate(__SESSION.data.uaccess[0].company_id, branchForm[0].id, user_id, branchForm[0].name, branchForm[0].active )
            if (res === 'name_exist') {
                setBranchForm(
                    (prev) => (prev.map(v=>v.id===branchForm[0].id ? {...v, error:{...v.error, name:res === 'name_exist' }} : {...v} ))
                )
                setSubmitFormLoader((prev) => ({...prev, loader:false}))
                return
            }
        }
        setLoader({...loader, snackbar:true, msg:`Successfully ${loc === 'create' ? 'Added!' : 'Updated!'}`})
        setSubmitFormLoader((prev) => ({...prev, loader:false}))
        nav.push('/setup/branch/list')
    }

    return (
        <Box minHeight="80px" display="flex" alignItems="center" justifyContent="flex-end" gap={2}>
            <Box onClick={backHandler} component={Button} disableFocusRipple={true} minHeight="50px" minWidth="120px" borderRadius={3} display="flex" alignItems="center" justifyContent="center" bgcolor="#fff" color="#00000061" sx={{border:"1px solid #EBEBEB"}} startIcon={<ArrowLeftIcons stroke="#00000061"/>} px={3}>
                <Box fontSize={14} fontWeight={400} lineHeight="19px">Back</Box>
            </Box>
            <Box disabled={submitFormLoader.submit} onClick={submitHandler} component={Button} disableFocusRipple={true} minHeight="50px" minWidth="120px" borderRadius={3} display="flex" alignItems="center" justifyContent="center" bgcolor={submitFormLoader.submit?"#fff":"primary.main"} color={submitFormLoader.submit?"#00000061":"#fff"} sx={{border:"1px solid #EBEBEB", ':hover':{bgcolor:'primary.main', color:'#FFFFFF'}}} px={3}>
                <Box fontSize={14} fontWeight={400} lineHeight="19px">{loc === 'create' ? 'Save' : 'Update'}</Box>
            </Box>
        </Box>
    )
}