import { useContext, useEffect, useState } from "react";
import { Backdrop, Box, CircularProgress, Stack } from '@mui/material';
import { BranchProductRead, BranchRead } from "../../../global/API";
import { ToolContext } from "../../../../../core/context/ToolContext";
import { Thead } from "./Thead";
import { Tbody } from "./Tbody";
import { Tfooter } from "./Tfooter";
import { CstmSnackBar } from "../../../../../core/global/forms/CstmSnackBar";
import { Generate } from "./Generate";

export const Index = () => {
    const { tool_state, setup_state, report_state } = useContext(ToolContext)
    const { __SESSION } = tool_state
    const { branchData, snackBarLoader } = setup_state
    const { salesProductList } = report_state

    const [form, setForm] = useState({branch_id:0, product_id:0, from:'', to:''})
    const [comProductIds, setComProductIds] = useState([])
    const [branchProductData, setBranchProductData] = useState([{id:0, sub_cat_name:'All', product_name:'', code:'000', active:1}])
    const [generateForm, setGenerateForm] = useState({infiniteLoad:false, limit:100, offset:0})
    const [salesProductData, setSalesProductData] = useState([])

    const inputChangeHandler = async(e, val, key) => {
        let name
        let value
        if (key === 'branch_id' || key === 'product_id' ) {
            name = key;
            value = (val === null ? 0 : val.id);
            if (key === 'branch_id') {
               await __getBranchProduct(value)
            }else{
                if (val) {
                    await __getProduct(val)
                }
            }
        }else{
            name = e.target.name
            value = e.target.value
        }   
        if (key==='branch_id') {
            setForm({...form, [name]:value, product_id:key==='branch_id'? 0 : form.product_id})
        }else{
            setForm({...form, [name]:value})
        }
        setSalesProductData([])
        setGenerateForm((prev) => ({...prev, infiniteLoad:false, offset:0}))
    }

    const __getProduct = async(val) => {
        if (val.id !== 0 && form.branch_id === 0) {
            let qsp = {
                sub_category_id:val.sub_category_id,
                product_id:val.company_product_id,
                filter:'all-products'
            }
            let product_id = []
            let res = await BranchProductRead(qsp)
            if (res) {
                res.filter((v) => product_id.push(v.id))
                setComProductIds(product_id)
            }
        }
    }

    const __getBranchProduct = async(id) => {
        let qsp = {
            show:2,
            company_id:__SESSION.data.uaccess[0].company_id,
            branch_id:id,
            filter:'reports'
        }
        let res = await BranchProductRead(qsp)
        if (res) setBranchProductData([{id:0, sub_cat_name:'All', product_name:'', code:'000', active:1}, ...res])
    }

    const __init = async() => {
        let res = await BranchRead(__SESSION.data.uaccess[0].company_id, 'active')
        if (res) branchData.set([{id:0, name:'All', code:'000', active:1}, ...res])
        await __getBranchProduct(0)
    }

    useEffect(() => {
        __init()
        //eslint-disable-next-line
    }, [])

    return (
        <>
            <Generate __SESSION={__SESSION} snackBarLoader={snackBarLoader} form={form} comProductIds={comProductIds} generateForm={generateForm} setGenerateForm={setGenerateForm} salesProductData={salesProductData} setSalesProductData={setSalesProductData} salesProductList={salesProductList} />
            <Thead inputChangeHandler={inputChangeHandler} form={form} branchData={branchData} branchProductData={branchProductData} snackBarLoader={snackBarLoader}/>
            <Tbody salesProductList={salesProductList.data} generateForm={generateForm}/>
            <Tfooter salesProductList={salesProductList.data}/>
            <Backdrop open={generateForm.infiniteLoad} sx={{zIndex: '1300'}}>
                <Box height="150px" width="100%" display="flex" alignItems="center" justifyContent="center">
                    <Box height="100%" width="270px" borderRadius="12px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={3}>
                        <Stack sx={{ color: 'grey.300' }} spacing={2} direction="row">
                            <CircularProgress color="inherit"  />
                        </Stack>
                        <Box color="#fff">Fetching data ...</Box>
                    </Box>
                </Box>
            </Backdrop>
            {snackBarLoader.data.snackbar && (
                <CstmSnackBar value={snackBarLoader.data} setter={snackBarLoader.set} msg={snackBarLoader.data.msg} type='error' /> 
            )}
        </>
    )
}