import { useEffect, useState } from "react";
import { Box } from "@mui/material"
import { CategoryRead } from "../../../global/API";
import { Top } from "./Top";
import { Form } from "./Form";
import { Bottom } from "./Bottom";
import { BackDropLoader } from "../../loader/BackDropLoader";

export const Setup = ({loc, categoryList, loader, setLoader, __SESSION}) => {
    let user_id = parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]);
    let cid = categoryList.data.length
    let categoryName = loc === 'create' ? '' : JSON.parse(window.atob(loc)).name
    let categoryActive = loc === 'create' ? 1 : JSON.parse(window.atob(loc)).active
    let series_num = loc === 'create' ? `CTGRY-000000000${cid + 1}` : JSON.parse(window.atob(loc)).series_num

    const [categoryForm, setCategoryForm] = useState([
        { 
            id:loc === 'create' ? cid + 1 : JSON.parse(window.atob(loc)).id,
            series_num:series_num,
            category_name:categoryName, 
            is_default:loc === 'create' ? 0 : JSON.parse(window.atob(loc)).is_default,
            error:{stat:true, name:false, msg:'Fields Required!'},
            active:categoryActive,
            sub:[
                { id:0, sub_name:'', sub_error:{stat:true, name:false, msg:'Fields Required!'} }
            ]
        }
    ])

    const [submitFormLoader, setSubmitFormLoader] = useState({submit:true, loader:false})
    
    useEffect(() => {
        const allTrue = categoryForm.some(item => item.error.stat || item.sub.some(subItem => subItem.sub_error.stat)) ? 1 : 0;
        setSubmitFormLoader({...submitFormLoader, submit:allTrue===0?false:true})
        // eslint-disable-next-line
    }, [categoryForm])

    const getSubCategoryData = async() => {
        if (loc !== 'create') { 
            let res = await CategoryRead(JSON.parse(window.atob(loc)).id, 'sub-category', 'all')
            if (res) {
                let sdata = res.map(v => { return {...v, remove:false, edit:false, sub_name:v.name, sub_error:{stat:false, name:false, msg:'Fields Required!'}} } )
                setCategoryForm(categoryForm.map(v => { return {...v, error:{...v.error, stat:false}, sub:sdata} }))
            }
        }
    }

    useEffect(() => {
        getSubCategoryData()
        // eslint-disable-next-line
    }, [])

    return (
        <Box height="100%" width="100%" borderRadius="12px" display="flex" flexDirection="column" justifyContent="flex-start" gap={3}>
            <Top loc={loc} cid={cid} categoryForm={categoryForm} setCategoryForm={setCategoryForm} />
            <Box minHeight="10px" my={-1.5} fontSize={10} textAlign="right" color="red">All Input Fields are Required *</Box>
            <Form loc={loc} cid={cid} categoryForm={categoryForm} setCategoryForm={setCategoryForm} />
            <Bottom loc={loc} user_id={user_id} __SESSION={__SESSION} submitFormLoader={submitFormLoader} setSubmitFormLoader={setSubmitFormLoader} categoryForm={categoryForm} setCategoryForm={setCategoryForm} loader={loader} setLoader={setLoader} />
            <BackDropLoader submitFormLoader={submitFormLoader} />
        </Box>
    )
}