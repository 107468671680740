import { WarningAmber } from '@mui/icons-material'
import { Box } from '@mui/material'

export const Unavailable = ({type}) => {
 
    return (
        <Box height="250px" width="100%" display="flex" justifyContent="center" pt={5}>
            <Box width={'456px'} bgcolor="#FFFFFF" px="24px" pb="24px" borderRadius="6px">
                <Box minHeight="52px"></Box>
                <Box display="flex" flexDirection="column" gap="20px">
                    <WarningAmber/>
                    <Box color="#292C2F" fontSize={16} fontWeight={600} lineHeight="20px" >Unavailable on {type === 'desktop' ? 'Desktop' : 'Mobile'} Devices</Box>
                    <Box color="#5B6167" fontSize={14} fontWeight={400} lineHeight="21px" >
                        The team is currently in the process of development. For now, please use a {type === 'desktop' ? 'Mobile' : 'Desktop'} device to access the system.
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
