import { useState } from "react"

export const SessionStates = () => {
    const [sessionData, setSessionData] = useState({modal:true, isShow:true, branch_id:0, branch_name:''})
    const [navSession, setNavSession] = useState({isShow:false})

    return {
        sessionData: {data: sessionData, set: setSessionData},
        navSession: {data: navSession, set: setNavSession}
    }
}