import { useContext, useEffect, useState } from "react";
import { Backdrop, Box, CircularProgress, Stack } from '@mui/material';
import { BranchRead, ToolUser } from "../../../global/API";
import { ToolContext } from "../../../../../core/context/ToolContext";
import { removeDuplicates } from "../../../../../core/validator/Validator";
import { CstmSnackBar } from "../../../../../core/global/forms/CstmSnackBar";
import { Thead } from "./Thead";
import { Tbody } from "./Tbody";
import { Tfooter } from "./Tfooter";
import { Generate } from "./Generate";

export const Index = () => {
    const { tool_state, setup_state } = useContext(ToolContext)
    const { __SESSION } = tool_state
    const { branchData, toolUser, snackBarLoader } = setup_state

    const [form, setForm] = useState({branch_id:0, user_id:0, from:'', to:''})
    const [generateForm, setGenerateForm] = useState({infiniteLoad:false, limit:100, offset:0})
    const [salesBranchList, setSalesBranchList] = useState([])
    const [salesBranchData, setSalesBranchData] = useState([])

    const inputChangeHandler = async(e, val, key) => {
        let name
        let value
        if (key === 'branch_id' || key === 'user_id' ) {
            name = key;
            value = (val === null ? 0 : val.id);
        }else{
            name = e.target.name
            value = e.target.value
        }
        setForm({...form, [name]:value})
        setSalesBranchData([])
        setGenerateForm((prev) => ({...prev, infiniteLoad:false, offset:0}))
    }

    const __init = async() => {
        let res = await BranchRead(__SESSION.data.uaccess[0].company_id, 'active')
        if (res) branchData.set([{id:0, name:'All', code:'000', active:1}, ...res])
        let res_user = await ToolUser(__SESSION.data.uaccess[0].company_id)
        let data = res_user.users.filter(v=>{return v.active === 1})
        const uniqueData = removeDuplicates(data);
        if (res_user) toolUser.set([{id:0, first_name:'All', last_name:'', active:1}, ...uniqueData]) 
    }

    useEffect(() => {
        __init()
        //eslint-disable-next-line
    }, [])

    return (
        <>
            <Generate __SESSION={__SESSION} snackBarLoader={snackBarLoader} form={form} generateForm={generateForm} setGenerateForm={setGenerateForm} salesBranchData={salesBranchData} setSalesBranchData={setSalesBranchData} salesBranchList={salesBranchList} setSalesBranchList={setSalesBranchList} />
            <Thead inputChangeHandler={inputChangeHandler} form={form} branchData={branchData} toolUser={toolUser} snackBarLoader={snackBarLoader}/>
            <Tbody salesBranchList={salesBranchList} generateForm={generateForm}/>
            <Tfooter salesBranchList={salesBranchList}/>
            <Backdrop open={generateForm.infiniteLoad} sx={{zIndex: '1300'}}>
                <Box height="150px" width="100%" display="flex" alignItems="center" justifyContent="center">
                    <Box height="100%" width="270px" borderRadius="12px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap={3}>
                        <Stack sx={{ color: 'grey.300' }} spacing={2} direction="row">
                            <CircularProgress color="inherit"  />
                        </Stack>
                        <Box color="#fff">Fetching data ...</Box>
                    </Box>
                </Box>
            </Backdrop>
            {snackBarLoader.data.snackbar && (
                <CstmSnackBar value={snackBarLoader.data} setter={snackBarLoader.set} msg={snackBarLoader.data.msg} type='error' /> 
            )}
        </>
    )
}