import { useState } from "react";
import { Box } from "@mui/material"
import { BackDropLoader } from "../../loader/BackDropLoader";
import { Top } from "./Top";
import { Form } from "./Form";
import { Bottom } from "./Bottom";

export const Setup = ({loc, branchList, loader, setLoader, __SESSION}) => {
    let user_id = parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]);
    let bid = branchList.data.length
    let branchCode = loc === 'create' ? '' : JSON.parse(window.atob(loc)).code
    let branchName = loc === 'create' ? '' : JSON.parse(window.atob(loc)).name
    let branchActive = loc === 'create' ? 1 : JSON.parse(window.atob(loc)).active
    
    const [branchForm, setBranchForm] = useState([{
        id:loc === 'create' ? bid + 1 : JSON.parse(window.atob(loc)).id, 
        code:branchCode, 
        name:branchName, 
        error:{stat:loc === 'create' ? true : false, code:false, name:false, msg:'Fields Required!'}, 
        active:branchActive 
    }])
    const [submitFormLoader, setSubmitFormLoader] = useState({submit:true, loader:false})
    
    return (
        <Box height="100%" width="100%" borderRadius="12px" display="flex" flexDirection="column" justifyContent="flex-start" gap={3}>
            <Top loc={loc} bid={bid} submitFormLoader={submitFormLoader} setSubmitFormLoader={setSubmitFormLoader} branchForm={branchForm} setBranchForm={setBranchForm} />
            <Box minHeight="10px" my={-1.5} fontSize={10} textAlign="right" color="red">All Input Fields are Required *</Box>
            <Form loc={loc} bid={bid} branchForm={branchForm} setBranchForm={setBranchForm} submitFormLoader={submitFormLoader} setSubmitFormLoader={setSubmitFormLoader} />
            <Bottom loc={loc} user_id={user_id} __SESSION={__SESSION} loader={loader} setLoader={setLoader} branchForm={branchForm} setBranchForm={setBranchForm} submitFormLoader={submitFormLoader} setSubmitFormLoader={setSubmitFormLoader} />
            <BackDropLoader submitFormLoader={submitFormLoader}/>
        </Box>
    )
}