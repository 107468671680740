import { Box, Grid } from "@mui/material"
import { NoDataFound } from "../../../empty/Index"
import moment from "moment"

export const Tbody = ({salesProductList, generateForm}) => {
    
    return (
        <Box height="100%" width="100%" bgcolor="#FFFFFF" borderRadius="12px" className="overflowY" display="flex" flexDirection="column" gap="12px" p={2}>
            {(!generateForm.infiniteLoad && salesProductList.length === 0) && <NoDataFound/>}
            {(!generateForm.infiniteLoad && salesProductList.length > 0) && salesProductList.map((v,k) => (
                <Grid key={k} container direction="row" sx={{p:2, border:'1px solid #EBEBEB', borderRadius:'10px'}}>
                    <Grid item xs={2.5}>{v.branch_name}</Grid>
                    <Grid item xs={3}>{v.sub_cat_name} {v.product_name}</Grid>
                    <Grid item xs={2.5} textAlign="center">{moment(v.created_at).format("YYYY-MM-DD h:mm a")}</Grid>
                    <Grid item xs={2} textAlign="center">{v.quantity}</Grid>
                    <Grid item xs={2} textAlign="center">₱ {parseFloat(v.amount).toFixed(2)}</Grid>
                </Grid>
            ))}
        </Box>
    )
}