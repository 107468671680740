import { useContext } from "react"
import { List } from "./list/Index"
import { Setup } from "./setup/Index"
import { ToolContext } from "../../../../core/context/ToolContext"

export const BranchProduct = ({match}) => {
    const { setup_state } = useContext(ToolContext)
    const { snackBarLoader } = setup_state

    let loc = match.params.params

    if (loc === 'list') {
        return <List loader={snackBarLoader.data} setLoader={snackBarLoader.set}/>
    }else{
        return <Setup loc={loc} loader={snackBarLoader.data} setLoader={snackBarLoader.set}/>
    }
}