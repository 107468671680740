import { Box, Button, Modal, Slide } from "@mui/material"
import { WarningAmber } from '@mui/icons-material';
import { SnakeLoader } from "../../../../../core/loader/SnakeLoader";

export const ConfirmationWarning = ({sm, submitFormLoader, submitHandler, eodSales}) => {

    return (
        <Modal open={submitFormLoader.modal} onClose={()=>submitHandler('cancel')} sx={{zIndex:1304}} className="noutlined">
            <Slide in={submitFormLoader.modal} direction="up" className="noutlined">
                <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center" px={sm && 2}>
                    <Box width={sm ? '100%' : '456px'} bgcolor="#FFFFFF" px="24px" pb="24px" borderRadius="6px">
                        <Box minHeight="52px"></Box>
                        <Box display="flex" flexDirection="column" gap="20px">
                            <WarningAmber/>
                            <Box color="#292C2F" fontSize={16} fontWeight={600} lineHeight="20px" >{eodSales.total_sales !== 0 ? 'Proceed with Saving the Data?' : 'No transaction records in your POS branch yet.'}</Box>
                            <Box color="#5B6167" fontSize={14} fontWeight={400} lineHeight="21px" >
                                {eodSales.total_sales !== 0 ?
                                    'Once Saved, No Further Edits Can Be Made.'
                                :
                                    ' You must complete a transaction before submitting the end-of-day sales report. Once submitted, it cannot be edited, and you can only transact again the next day.'
                                }
                            </Box>
                            <Box display="flex" alignItems="center" minHeight={40}>
                                <Button disabled={submitFormLoader.loader} fullWidth variant="outlined" onClick={()=>submitHandler('cancel')}
                                    sx={{mr: 1, height: 40, borderRadius: '6px', bgcolor: '#E2EDE7', '&:hover':{bgcolor: '#A4CAB4'} }}>
                                        Cancel
                                </Button>
                                {eodSales.total_sales !== 0 && (
                                    <Button disabled={submitFormLoader.loader} fullWidth variant='contained' disableElevation onClick={()=>submitHandler('save')}
                                        sx={{height: 40, borderRadius: '6px',  }}
                                    >
                                        Save
                                        <Box pt="8px">{submitFormLoader.loader && <SnakeLoader size="5px" bg="#FFFFFF" distance="2.5px" />}</Box>
                                    </Button>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </Box>
              
            </Slide>
        </Modal>
    )
}