import { env } from "../../../core/Env"
import { rqx } from "../../../core/request/API"

export const BranchRead = async(company_id, filter) => {
    try {
        let result = await rqx.get(`<<biz>>/${env() === 'dev' ? 'local' : env()}/biz/branch/read`, {
            company_id: company_id, 
            filter:  filter
        })
        return result
    } catch (error) {
        return 'error'
    }   
}

export const BranchCreate = async(company_id, user_id, branch_code, branch_name ) => {
    try {
        let result = await rqx.post(`<<biz>>/${env() === 'dev' ? 'local' : env()}/biz/branch/create`, {
            company_id: company_id, 
            user_id:user_id,
            branch_code:branch_code,
            branch_name:branch_name,
        })
        return result
    } catch (error) {
        return 'error'
    }   
}

export const BranchUpdate = async(company_id, id, user_id, branch_name, active ) => {
    try {
        let result = await rqx.post(`<<biz>>/${env() === 'dev' ? 'local' : env()}/biz/branch/update`, {
            company_id: company_id, 
            id:id,
            user_id:user_id,
            branch_name:branch_name,
            active:active,
        })
        return result
    } catch (error) {
        return 'error'
    }   
}

export const CategoryRead = async(cid, filter, type) => {
    try {
        let result
        if (filter === 'sub-category') {
            result = await rqx.get(`<<biz>>/${env() === 'dev' ? 'local': env()}/biz/category/read`, {
                category_id: cid, 
                filter:filter,
                type:type
            })  
        }else{
            result = await rqx.get(`<<biz>>/${env() === 'dev' ? 'local' : env()}/biz/category/read`, {
                company_id: cid, 
                filter:filter,
                type:type
            })
        }
        return result
    } catch (error) {
        return 'error'
    }   
}

export const CategoryCreate = async(company_id, user_id, name, sub) => {
    try {
        let result = await rqx.post(`<<biz>>/${env() === 'dev' ? 'local' : env()}/biz/category/create`, {
            company_id: company_id, 
            user_id:user_id,
            name:name,
            sub:sub,
        })
        return result
    } catch (error) {
        return 'error'
    }   
}
export const CategoryUpdate = async(company_id, user_id, category_id, category_name, active, sub) => {
    try {
        let result = await rqx.post(`<<biz>>/${env() === 'dev' ? 'local' : env()}/biz/category/update`, {
            company_id: company_id, 
            user_id:user_id,
            category_id:category_id,
            name:category_name,
            active:active,
            sub:sub
        })
        return result
    } catch (error) {
        return 'error'
    }   
}

export const ProductRead = async(company_id, filter) => {
    try {
        let result = await rqx.get(`<<biz>>/${env() === 'dev' ? 'local' : env()}/biz/product/read`, {
            company_id: company_id, 
            filter:  filter
        })
        return result
    } catch (error) {
        return 'error'
    }   
}

export const ProductCreate = async(body) => {
    try {
        let result = await rqx.post(`<<biz>>/${env() === 'dev' ? 'local' : env()}/biz/product/create`, body)
        return result
    } catch (error) {
        return 'error'
    }   
}

export const ProductUpdate = async(body) => {
    try {
        let result = await rqx.post(`<<biz>>/${env() === 'dev' ? 'local' : env()}/biz/product/update`, body)
        return result
    } catch (error) {
        return 'error'
    }   
}

export const BranchProductRead = async(qsp) => {
    try {
        let result = await rqx.get(`<<biz>>/${env() === 'dev' ? 'local' : env()}/biz/branchProduct/read`, qsp)
        return result
    } catch (error) {
        return 'error'
    }   
}

export const BranchProductCreate = async(body) => {
    try {
        let result = await rqx.post(`<<biz>>/${env() === 'dev' ? 'local' : env()}/biz/branchProduct/create`, body)
        return result
    } catch (error) {
        return 'error'
    }   
}

export const BranchProductUpdate = async(body) => {
    try {
        let result = await rqx.post(`<<biz>>/${env() === 'dev' ? 'local' : env()}/biz/branchProduct/update`, body)
        return result
    } catch (error) {
        return 'error'
    }   
}

export const BranchUserRead = async(qsp) => {
    try {
        let result = await rqx.get(`<<biz>>/${env() === 'dev' ? 'local' : env()}/biz/branchUser/read`, qsp)
        return result
    } catch (error) {
        return 'error'
    }   
}

export const BranchUserCreate = async(body) => {
    try {
        let result = await rqx.post(`<<biz>>/${env() === 'dev' ? 'local' : env()}/biz/branchUser/create`, body)
        return result
    } catch (error) {
        return 'error'
    }   
}

export const BranchUserUpdate = async(body) => {
    try {
        let result = await rqx.post(`<<biz>>/${env() === 'dev' ? 'local' : env()}/biz/branchUser/update`, body)
        return result
    } catch (error) {
        return 'error'
    }   
}

export const UomRead = async() => {
    try {
        let result = await rqx.get(`<<biz>>/${env() === 'dev' ? 'local' : env()}/biz/uom/read`)
        return result
    } catch (error) {
        return 'error'
    }   
}

export const ToolUser = async(company_id) => {
    try {
        // let result = await rqx.get(`<<global>>/${env() === 'local' ? 'prod' : env()}/tooluser/gettooluser`, {company_id: 35, tool_id: 103})
        let result = await rqx.get(`<<global>>/${env() === 'local' ? 'dev' : env()}/tooluser/gettooluser`, {company_id: company_id, tool_id: process.env.REACT_APP_TID_BIZ})
        return result
    } catch (error) {
        return 'error'
    }   
}


///USER API'S
export const OrderSave = async(body) => {
    try {
        let result = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/order/create`, body)
        return result
    } catch (error) {
        return 'error'
    }   
}

export const SalesReportRead = async(qsp) => {
    try {
        let result = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/salesreport/read`, qsp)
        return result
    } catch (error) {
        return 'error'
    }   
}

export const SalesReportSubmit = async(body) => {
    try {
        let result = await rqx.post(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/salesreport/create`, body)
        return result
    } catch (error) {
        return 'error'
    }   
}


///ADMIN REPORTS
export const SalesBranchReport = async(qsp) => {
    try {
        let result = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/report/salesbranch`, qsp)
        return result
    } catch (error) {
        return 'error'
    }   
}

export const SalesProductReport = async(qsp) => {
    try {
        let result = await rqx.get(`<<tool>>/${env() === 'dev' ? 'local' : env()}/biz/report/salesproduct`, qsp)
        return result
    } catch (error) {
        return 'error'
    }   
}