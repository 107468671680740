import { Box, IconButton } from "@mui/material"
import { NewAddRoundIcon } from "../../../../../core/global/Icons"

export const Top = (props) => {
    const { loc, bpid, branchProductForm, setBranchProductForm } = props

    const addRowHandler = () => {
        setBranchProductForm([...branchProductForm, 
            {
                id:bpid + branchProductForm.length + 1,
                series_num:`BP-000000000${bpid + branchProductForm.length + 1}`, 
                branch_id:0, 
                error:{stat:true, msg:'Fields Required!'},
                active:1,
                product:[
                    {
                        id:bpid + branchProductForm.length + 1,
                        category_id:0,
                        sub_category_id:0,
                        sub_cat:[],
                        product_id:0,
                        price:0, 
                        quantity:0, 
                        discount:0,
                        prod_error:{stat:true, product_id:false, msg:'Fields Required!'},
                        file:[],
                        file_name:'',
                        file_size:'',
                        file_type:'',
                        file_link:''
                    }
                ]
            }
        ])
    }  

    return (
        <Box minHeight="80px" bgcolor="#ECECEC" borderRadius="10px" display="flex" alignItems="center" justifyContent="space-between" px={3}>
            <Box component="strong" fontSize={16}>{loc === 'create' ? 'Add' : 'Update'} Branch Product</Box>
            {loc === 'create' && (
                (branchProductForm.length < 5 ?
                    <IconButton onClick={addRowHandler} variant="contained" sx={{bgcolor:'primary.main', ':hover':{bgcolor:'#1B984F'} }} >
                        <NewAddRoundIcon/>
                    </IconButton>
                :
                    <Box component="strong" fontSize={12} color="red">Add Up to 5 Rows Only!</Box>
                )
            )}
        </Box>
    )
}