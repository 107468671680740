import { Box, IconButton } from "@mui/material"
import { NewAddRoundIcon } from "../../../../../core/global/Icons"

export const Top = (props) => {
    const { loc, pid, productForm, setProductForm } = props

    const addRowHandler = () => {
        setProductForm([...productForm,
            {
                id:pid + productForm.length + 1,
                series_num:`PR-00000000${pid + productForm.length + 1}`, 
                product_name:'', 
                description:'', 
                uom_id:0,
                error:{stat:true, name:false, msg:'Fields Required!'},
                active:1,
            }
        ])
    }

    return (
        <Box minHeight="80px" bgcolor="#ECECEC" borderRadius="10px" display="flex" alignItems="center" justifyContent="space-between" px={3}>
            <Box component="strong" fontSize={16}>{loc === 'create' ? 'Add' : 'Update' } Product</Box>
            {loc === 'create' && (
                (productForm.length < 5 ?
                    <IconButton onClick={addRowHandler} variant="contained" sx={{bgcolor:'primary.main', ':hover':{bgcolor:'#1B984F'} }} >
                        <NewAddRoundIcon/>
                    </IconButton>
                :
                    <Box component="strong" fontSize={12} color="red">Add Up to 5 Rows Only!</Box>
                )
            )}
        </Box>
    )
}