import { useHistory } from "react-router-dom";
import { Box, Button } from "@mui/material"
import { ProductCreate, ProductUpdate } from "../../../global/API";
import { ArrowLeftIcons } from "../../../../../core/global/Icons"

export const Bottom = (props) => {
    let nav = useHistory()
    const { loc, user_id, __SESSION, loader, setLoader, productForm, setProductForm, submitFormLoader, setSubmitFormLoader } = props

    const backHandler = () => {
        nav.push('/setup/product/list')
    }
    
    const submitHandler = async() => {
        setSubmitFormLoader((prev) => ({...prev, loader:true}))
        if (loc === 'create') {
            let data = [...productForm]
            for (let i = 0; i < data.length; i++) {
                let body = {
                    company_id: __SESSION.data.uaccess[0].company_id, 
                    user_id:user_id,
                    product_name:data[i].product_name,
                    description:data[i].description,
                    uom_id:data[i].uom_id,
                    vat_id:1,
                }
                let res = await ProductCreate(body)
                if (res === 'name_exist') {
                    setProductForm(
                        (prev) => (prev.map(v=>v.id===data[i].id ? {...v, error:{...v.error, name:res === 'name_exist' }} : {...v} ))
                    )
                    setSubmitFormLoader((prev) => ({...prev, loader:false}))
                    return
                }else{
                    setProductForm(
                        (prev) => (prev.filter(v=>{return v.id!==data[i].id} ))
                    )
                }
            }
        }else{
            let data = productForm[0]
            let body = {
                id:data.id,
                company_id: __SESSION.data.uaccess[0].company_id, 
                user_id:user_id,
                product_name:data.product_name,
                description:data.description,
                uom_id:data.uom_id,
                active:data.active,
            }
            let res = await ProductUpdate(body)
            if (res === 'name_exist') {
                setProductForm(
                    (prev) => (prev.map(v=>v.id===data.id ? {...v, error:{...v.error, name:res === 'name_exist' }} : {...v} ))
                )
                setSubmitFormLoader((prev) => ({...prev, loader:false}))
                return
            }
        }
        setLoader({...loader, snackbar:true, msg:`Successfully ${loc === 'create' ? 'Added!' : 'Updated!'}`})
        setSubmitFormLoader((prev) => ({...prev, loader:false}))
        nav.push('/setup/product/list')
    }


    return (
        <Box minHeight="80px" display="flex" alignItems="center" justifyContent="flex-end" gap={2}>
            <Box onClick={backHandler} component={Button} disableFocusRipple={true} minHeight="50px" minWidth="120px" borderRadius={3} display="flex" alignItems="center" justifyContent="center" bgcolor="#fff" color="#00000061" sx={{border:"1px solid #EBEBEB"}} startIcon={<ArrowLeftIcons stroke="#00000061"/>} px={3}>
                <Box fontSize={14} fontWeight={400} lineHeight= "19px">Back</Box>
            </Box>
            <Box disabled={submitFormLoader.submit} onClick={submitHandler} component={Button} disableFocusRipple={true} minHeight="50px" minWidth="120px" borderRadius={3} display="flex" alignItems="center" justifyContent="center" bgcolor={submitFormLoader.submit?"#fff":"primary.main"} color={submitFormLoader.submit?"#00000061":"#fff"} sx={{border:"1px solid #EBEBEB", ':hover':{bgcolor:'primary.main', color:'#FFFFFF'}}} px={3}>
                <Box fontSize={14} fontWeight={400} lineHeight="19px">{loc === 'create' ? 'Save' : 'Update'}</Box>
            </Box>
        </Box>
    )
}