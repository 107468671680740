import { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material"
import { ToolContext } from "../../../../../core/context/ToolContext";
import { BranchRead } from "../../../global/API";
import { CstmSnackBar } from "../../../../../core/global/forms/CstmSnackBar";
import { Top } from "./Top";
import { Form } from "./Form";
import { Bottom } from "./Bottom";
import { BackDropLoader } from "../../loader/BackDropLoader";

export const Setup = ({loc, loader, setLoader }) => {
    const { tool_state, setup_state } = useContext(ToolContext)
    const { __SESSION } = tool_state
    const { branchUserList, branchData, toolUser } = setup_state
    let user_id = parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]);
    let bcode = 20
    let buid = branchUserList.data.length
    let branch_id = loc === 'create' ? 0 : JSON.parse(window.atob(loc)).branch_id
    let uid = loc === 'create' ? 0 : JSON.parse(window.atob(loc)).user_id
    let branchUserActive = loc === 'create' ? 1 : JSON.parse(window.atob(loc)).active

    const [branchUserForm, setBranchUserForm] = useState([
        {
            id:loc === 'create' ? buid + 1 : JSON.parse(window.atob(loc)).id, 
            series_num:`BUSR-000000000${bcode + 1}`, 
            branch_id:branch_id, 
            user_id:uid, 
            error:{stat:true, name:false, msg:'Fields Required!'},
            active:branchUserActive,
        }
    ])
    
    const [submitFormLoader, setSubmitFormLoader] = useState({submit:true, loader:false})

    const getGlobalData = async() => {
        let res = await BranchRead(__SESSION.data.uaccess[0].company_id, 'active')
        if (res) branchData.set(res)
    }

    useEffect(() => {
        getGlobalData()
        //eslint-disable-next-line
    }, [])

    return (
        <Box height="100%" width="100%" borderRadius="12px" display="flex" flexDirection="column" justifyContent="flex-start" gap={3}>
            <Top loc={loc} buid={buid} branchUserForm={branchUserForm} setBranchUserForm={setBranchUserForm} />
            <Box minHeight="10px" my={-1.5} fontSize={10} textAlign="right" color="red">All Input Fields are Required *</Box>
            <Form loc={loc} buid={buid} branchUserForm={branchUserForm} setBranchUserForm={setBranchUserForm} submitFormLoader={submitFormLoader} setSubmitFormLoader={setSubmitFormLoader} branchData={branchData} toolUser={toolUser} />
            <Bottom loc={loc} user_id={user_id} __SESSION={__SESSION} loader={loader} setLoader={setLoader} submitFormLoader={submitFormLoader} setSubmitFormLoader={setSubmitFormLoader} branchUserForm={branchUserForm} setBranchUserForm={setBranchUserForm} />
            <BackDropLoader submitFormLoader={submitFormLoader}/>
            {loader.snackbar && (
                <CstmSnackBar value={loader} setter={setLoader} msg={loader.msg} type='error' /> 
            )}
        </Box>
    )
}