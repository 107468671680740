import { Index as MobileReports } from "./devices/Index"
import { Index as DesktopReports } from "./desktop/Index"
import { VM } from "../../../core/VM"

const view = VM()

export const Reports = ({match}) => { 
   
    return (
        view === '' ? 
            // DESKTOP
            <DesktopReports match={match}/>
        :  
            // MOBILE
            <MobileReports/>
    )
}
