// Libraries
import React from 'react';
import ReactDOM from 'react-dom';

import { Index as IndexBusiness } from './__business'
// import { Index as IndexPersonal } from './__personal'

// const loc = window.location.pathname
// const subdir = loc.split('/')[1]
let Core = IndexBusiness

// if (subdir === 'psl') {
// 	Core = IndexPersonal
// } else if(subdir === 'biz'){
// 	Core = IndexBusiness
// }
// else{
	// Core = IndexBusiness
	// Core = IndexPersonal
// }

ReactDOM.render(
<Core/>, document.getElementById('root'));