import moment from "moment"

export const DataSetArray = (salesBranchList) => {

    let totalsales = salesBranchList.reduce((prev, current) => {
        return prev + +current.total_sales;
    }, 0);
    let totalactualsales = salesBranchList.reduce((prev, current) => {
        return prev + +current.actual_sales;
    }, 0);
    let total_variance = salesBranchList.reduce((prev, current) => {
        return prev + +current.variance;
    }, 0);

    let footer =   [
        {type:'footer', name: '', first_name:'', last_name:'', date:'', total_sales:'', actual_sales:'', variance:'', remarks:''},
        {type:'footer', name: '', first_name:'', last_name:'', date:'', total_sales:'', actual_sales:'', variance:'', remarks:''},
        {type:'footer', name: '', first_name:'', last_name:'', date:'', total_sales:`₱ ${parseFloat(totalsales).toFixed(2)}`, actual_sales:`₱ ${parseFloat(totalactualsales).toFixed(2)}`, variance:`₱ ${parseFloat(total_variance).toFixed(2)}`, remarks:''},
        {type:'footer', name: '', first_name:'', last_name:'', date:'', total_sales:'Total Sales', actual_sales:'Total Actual Sales', variance:'Total Variance', remarks:''},
    ]
    
    const DataSet = [
        {
            columns: [
                {title:"Branch",style:{alignment:{horizontal:"center"}, font:{sz:"18",bold:true}},width:{wpx:150}},
                {title:"User",style:{alignment:{horizontal:"center"}, font:{sz:"18",bold:true}},width:{wpx:150}},
                {title:"Date",style:{alignment:{horizontal:"center"}, font:{sz:"18",bold:true}},width:{wpx:150}},
                {title:"Total Sales",style:{alignment:{horizontal:"center"}, font:{sz:"18",bold:true}},width:{wpx:150}},
                {title:"Actual Sales",style:{alignment:{horizontal:"center"}, font:{sz:"18",bold:true}},width:{wpx:170}},
                {title:"Variance",style:{alignment:{horizontal:"center"}, font:{sz:"18",bold:true}},width:{wpx:150}},
                {title:"remarks",style:{alignment:{horizontal:"center"}, font:{sz:"18",bold:true}},width:{wpx:350}}
            ],
            data:[...salesBranchList, ...footer].map((v) => [
                {value: v.name, style:{alignment:{horizontal:"center"}} },
                {value: `${v.first_name} ${v.last_name}`, style:{alignment:{horizontal:"center"}} },
                {value: v.type === 'footer' ? v.date:moment(v.date).format('YYYY-MM-DD'), style:{alignment:{horizontal:"center"}} },
                {value: v.type === 'footer' ? v.total_sales:`₱ ${parseFloat(v.total_sales).toFixed(2)}`, 
                    width:{wpx:150}, 
                    style: {alignment:{horizontal:v.total_sales === 'Total Sales' ? "center" : "right"}, font: v.type === 'footer' && {sz:"18",bold:true}} 
                },
                {value: v.type === 'footer' ? v.actual_sales:`₱ ${parseFloat(v.actual_sales).toFixed(2)}`, 
                    width:{wpx:170}, 
                    style: {alignment:{horizontal:v.actual_sales === 'Total Actual Sales' ? "center" : "right"}, font: v.type === 'footer' && {sz:"18",bold:true}} 
                },
                {value: v.type === 'footer' ? v.variance: `₱ ${parseFloat(v.variance).toFixed(2)}`, width:{wpx:150}, 
                    style:v.type === 'footer' ? {alignment:{horizontal:v.variance === 'Total Variance' ? "center" : "right"},font:{sz:"18",bold:true}} :{alignment:{horizontal:"right"}, fill: {patternType: "solid", fgColor: 
                    {rgb:(v.actual_sales === v.total_sales ? "FFCCEEFF" :  (v.actual_sales > v.total_sales ? "FF00FF00" : "FFFF0000")) }
                }}},
                {value:v.remarks, style:{alignment:{wrapText:true, horizontal:"center"}} },
            ])
        }
    ]
    return DataSet
}