import { useDropzone } from "react-dropzone";
import { Box, IconButton } from "@mui/material"
import UploadIcon from '@mui/icons-material/Upload';

export const DropzoneWrapper = ({index, subIndex, branchProductForm, val, loader, setLoader, submitFormLoader, setSubmitFormLoader, loc}) => {
    const onDrop = (files) => {
        if (files.length > 0) {
            let sizelmt = 4194304
            if (files[0].size > sizelmt) {
                setLoader({...loader, snackbar:true, msg:`File uploads must not exceed 3MB.`})
                return
            }
            const data = [...branchProductForm];
            data[index]['product'][subIndex]['file'] = files
            data[index]['product'][subIndex]['file_name'] = files[0].name
            data[index]['product'][subIndex]['file_size'] = files[0].size
            data[index]['product'][subIndex]['file_type'] = files[0].type
            data[index]['product'][subIndex]['file_link'] = URL.createObjectURL(files[0])
           
            if (loc !== 'create') {
                setSubmitFormLoader({...submitFormLoader, submit:false})
            }
        }
    };

    const {getRootProps, getInputProps} =  useDropzone({onDrop, accept: { 'image/png': ['.png'], 'image/jpeg' : ['.jpeg'] } })

    return (
        <Box height="100%" width="100%" position="relative" {...getRootProps({className: 'dropzone'})}  border="2px dashed #D9D9D9" className="c-pointer" borderRadius={3} p={3} display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap={1}>
            {val.file_link !== '' ? 
                <>
                    <Box component="img" src={val.file_link} alt={val.file_link} height={60} width="auto" borderRadius="12px"/>
                    <Box fontSize={14} fontWeight={400} lineHeight="19px" color="#333333" sx={{width:'100%', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', textAlign:'center'}}>{val.file_name}</Box>
                </>
            :
                <>
                    <IconButton variant="contained" sx={{bgcolor:'primary.main', ':hover':{bgcolor:'primary.main'} }} >
                        <UploadIcon sx={{color:'white', height:"15", width:"15"}}/>
                    </IconButton>
                    <Box fontSize={14} fontWeight={400} lineHeight="19px" color="#333333">Drag and drop an image, or <Box component="label" color="#107038">Browse</Box></Box>
                    <Box textAlign="center" fontSize={12} fontWeight={400} lineHeight="19px" color="#737373">The maximum file size is 3 MB.</Box>
                </>
            }
            <input {...getInputProps()} />
        </Box>
    );
}