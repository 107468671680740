import { Box } from "@mui/material"
import { useContext, useEffect, useState } from "react";
import { ToolContext } from "../../../../../core/context/ToolContext";
import { BranchRead, CategoryRead, ProductRead } from "../../../global/API";
import { CstmSnackBar } from "../../../../../core/global/forms/CstmSnackBar";
// import { TxtNumber } from "../../../../../core/global/Format";
import { Top } from "./Top";
import { Form } from "./Form";
import { Bottom } from "./Bottom";
import { BackDropLoader } from "../../loader/BackDropLoader";

export const Setup = ({loc, loader, setLoader }) => {
    const { tool_state, setup_state } = useContext(ToolContext)
    const { __SESSION } = tool_state
    const { branchProductList, branchData, productData, categoryData } = setup_state
    let user_id = parseInt(atob(__SESSION.data.ainfo.aid).split('_', 2)[1]);
    let bpid = branchProductList.data.length
    let series_num = loc === 'create' ? `BP-000000000${bpid + 1}` : JSON.parse(window.atob(loc)).series_num
    let branch_id = loc === 'create' ? 0 : JSON.parse(window.atob(loc)).branch_id
    let product_id = loc === 'create' ? 0 : JSON.parse(window.atob(loc)).product_id
    let category_id = loc === 'create' ? 0 : JSON.parse(window.atob(loc)).category_id
    let sub_category_id = loc === 'create' ? 0 : JSON.parse(window.atob(loc)).sub_category_id
    let price = loc === 'create' ? 0 : parseFloat(JSON.parse(window.atob(loc)).price)
    let quantity = loc === 'create' ? 0 : parseFloat(JSON.parse(window.atob(loc)).quantity)
    let discount = loc === 'create' ? 0 : parseFloat(JSON.parse(window.atob(loc)).discount)
    let branchProductActive = loc === 'create' ? 1 : JSON.parse(window.atob(loc)).active
    let fileName = loc === 'create' ? '' : JSON.parse(window.atob(loc)).file_name
    let fileLink = loc === 'create' ? '' : JSON.parse(window.atob(loc)).link

    const [branchProductForm, setBranchProductForm] = useState([
        { 
            id:loc === 'create' ? bpid + 1 : JSON.parse(window.atob(loc)).id, 
            series_num:series_num, 
            branch_id:branch_id, 
            error:{stat:true, msg:'Fields Required!'},
            active:branchProductActive,
            product:[
                {
                    category_id:category_id,
                    sub_category_id:sub_category_id,
                    sub_cat:[],
                    product_id:product_id,
                    price:price, 
                    quantity:quantity, 
                    discount:discount,
                    prod_error:{stat:false, product_id:false, msg:'Fields Required!'},
                    file:[],
                    file_name:fileName,
                    file_size:'',
                    file_type:'',
                    file_link:fileLink
                }
            ]
        }
    ])  

    const [submitFormLoader, setSubmitFormLoader] = useState({submit:true, loader:false})
    
    const getGlobalData = async() => {
        let res = await BranchRead(__SESSION.data.uaccess[0].company_id, 'active')
        if (res) branchData.set(res)
        let res_product = await ProductRead(__SESSION.data.uaccess[0].company_id, 'active')
        if (res_product) productData.set(res_product)
        let res_category = await CategoryRead(__SESSION.data.uaccess[0].company_id, 'category', 'active')
        if (res_category) categoryData.set(res_category)
        if (loc !== 'create') {
            let data = [...branchProductForm]
            let res = await CategoryRead(category_id, 'sub-category', 'active')
            if (res) {
                data[0]['product'][0]['sub_cat'] = res
                setBranchProductForm(data)
            }
        }
    }

    useEffect(() => {
        getGlobalData()
        //eslint-disable-next-line
    }, [])
    
    return (
        <Box height="100%" width="100%" borderRadius="12px" display="flex" flexDirection="column" justifyContent="flex-start" gap={3}>
            <Top loc={loc} bpid={bpid} branchProductForm={branchProductForm} setBranchProductForm={setBranchProductForm} />
            <Box minHeight="10px" my={-1.5} fontSize={10} textAlign="right" color="red">All Input Fields are Required *</Box>
            <Form loc={loc} bpid={bpid} branchProductForm={branchProductForm} setBranchProductForm={setBranchProductForm} loader={loader} setLoader={setLoader} submitFormLoader={submitFormLoader} setSubmitFormLoader={setSubmitFormLoader} branchData={branchData} categoryData={categoryData} productData={productData}/>
            <Bottom loc={loc} user_id={user_id} __SESSION={__SESSION} loader={loader} setLoader={setLoader} submitFormLoader={submitFormLoader} setSubmitFormLoader={setSubmitFormLoader} branchProductForm={branchProductForm} setBranchProductForm={setBranchProductForm} /> 
            <BackDropLoader submitFormLoader={submitFormLoader}/>
            {loader.snackbar && (
                <CstmSnackBar value={loader} setter={setLoader} msg={loader.msg} type='error' /> 
            )}
        </Box>
    )
}