import { Box, Button, Grid, InputBase } from "@mui/material"
import { AddRoundIcon, EditProfile, SearchIcons } from "../../../../../core/global/Icons"
import { useHistory } from "react-router-dom"
import { PrimaryToolTip } from "../../../../../core/global/ToolTip"
import { ProductRead } from "../../../global/API"
import { useContext, useEffect } from "react"
import { CstmSnackBar } from "../../../../../core/global/forms/CstmSnackBar"
import { ToolContext } from "../../../../../core/context/ToolContext"
import { ListLoader } from "../../loader/ListLoader"
import { NoDataFound } from "../../../empty/Index"

export const List = ({loader, setLoader, __SESSION}) => {
    const nav = useHistory()
    const { setup_state } = useContext(ToolContext)
    const { productList, productSearchList, searchInput, listLoader } = setup_state

    const redirectionHandler = (type, value) => {
        setLoader({...loader, snackbar:false, msg:''})
        nav.push(`/setup/product/${type === 'create' ? type : window.btoa(JSON.stringify(value))}`)
    }

    const __init = async() => {
        listLoader.set((prev) => ({...prev.data, isloading:true}))
        let res = await ProductRead(__SESSION.data.uaccess[0].company_id, 'all')
        if (res) {
            productList.set(res)
            listLoader.set((prev) => ({...prev.data, isloading:false}))
        }
    }

    useEffect(() => {
        __init()
        // eslint-disable-next-line
    }, [])

    const searchHandler = () => {
        let sort_data = productList.data.filter(item => 
            item.series_num.toLowerCase().includes(searchInput.data.toLowerCase()) ||
            item.uom_name.toLowerCase().includes(searchInput.data.toLowerCase()) ||
            item.name.toLowerCase().includes(searchInput.data.toLowerCase())
        )
        productSearchList.set(sort_data)
        listLoader.set((prev) => ({...prev.data, isloading:false}))
    }

    useEffect(() => {
        if (!listLoader.data.isloading) {
            listLoader.set((prev) => ({...prev.data, isloading:true}))
        }
        const searchFcn = setTimeout(() => {
            if (searchInput.data === '') {
                listLoader.set((prev) => ({...prev.data, isloading:false}))
            }else{
                searchHandler()
            }
        }, 500);
        return () => clearTimeout(searchFcn)
        //eslint-disable-next-line
    }, [searchInput.data])

    return (
        <Box height="100%" width="100%" bgcolor="#FFFFFF" borderRadius="12px" display="flex" flexDirection="column" p={3}>
            <Box minHeight="120px" display="flex" flexDirection="column" gap={3}>
                <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
                    <InputBase
                        onInput={(e)=> searchInput.set(e.target.value)}
                        placeholder="Search"
                        inputProps={{ 'aria-label': 'search' }} endAdornment={ <Box pr={1} pt={1}><SearchIcons /></Box> }
                        sx={{backgroundColor: "#FFFFFF", border: "#D9EBE8 solid 2px", borderRadius: '10px', padding: '5px 5px 5px 20px', width: '291px', height: '49px'}}
                    />
                    <Box onClick={()=>redirectionHandler('create')} component={Button} disableFocusRipple={true} minHeight="50px" borderRadius={3} display="flex" alignItems="center" justifyContent="center" bgcolor="primary.main" color="#fff" sx={{border:"2px solid #EBEBEB", ':hover':{bgcolor:'primary.main', color:'#FFFFFF'}}} startIcon={<AddRoundIcon/>} px={3}>
                        <Box fontSize={16} fontWeight={400} lineHeight="19px">Product</Box>
                    </Box>
                    
                </Box>
                <Grid container direction="row" sx={{px:2}}>
                    <Grid item xs={3}>Product Number</Grid>
                    <Grid item xs={3}>Product</Grid>
                    <Grid item xs={2}>UOM</Grid>
                    <Grid item xs={2} textAlign="center">Status</Grid>
                    <Grid item xs={2} textAlign="center">Action</Grid>
                </Grid>
            </Box>

            <Box height="100%" width="100%" className="overflowY" display="flex" flexDirection="column" gap="12px">
                {listLoader.data.isloading && <ListLoader/>}
                {(!listLoader.data.isloading && ( searchInput.data === '' ? productList.data.length === 0 : productSearchList.data.length === 0 ) ) && <NoDataFound/>}
                {!listLoader.data.isloading && ( searchInput.data === '' ? productList.data: productSearchList.data ).map((v,k) => (
                    <Grid key={k} container direction="row" sx={{p:2, border:'1px solid #EBEBEB', borderRadius:'10px'}}>
                        <Grid item xs={3}>{v.series_num}</Grid>
                        <Grid item xs={3}>{v.name}</Grid>
                        <Grid item xs={2}>{v.uom_name}</Grid>
                        <Grid item xs={2} textAlign="center">{v.active === 1 ?"Active":"Inactive"}</Grid>
                        <Grid item xs={2} textAlign="center">
                            <PrimaryToolTip onClick={()=>redirectionHandler('edit', v)} title="Update" arrow sx={{margin: '0 14px 0 14px'}} placement="top">
                                <Box fontWeight={500} className="c-pointer">
                                    <EditProfile fill={'none'} stroke={'#292d32'}/>
                                </Box>
                            </PrimaryToolTip>
                        </Grid>
                    </Grid>
                ))}
            </Box>
            {loader.snackbar && (
                <CstmSnackBar value={loader} setter={setLoader} msg={loader.msg} type='success' /> 
            )}
        </Box>
    )
}